import { useContext } from 'react';
import { TranslationContext } from '../../../assets/contexts/translationContext';
import './Show.css';
import { BoltIcon } from '../../../assets/icons/icons';
import banner from '../../../assets/images/main/show/banner.webp';
import glitter from '../../../assets/images/main/show/glitter.webp';

function Show() {
  const {
    main: {
      show: { title, text },
    },
  } = useContext(TranslationContext);

  return (
    <div className="show">
      <div className="show__content">
        <img className="show__banner" src={banner} alt="" />
        <img
          className="show__glitter show__glitter_size_s"
          src={glitter}
          alt=""
        />
        <img
          className="show__glitter show__glitter_size_m"
          src={glitter}
          alt=""
        />
        <img
          className="show__glitter show__glitter_size_l"
          src={glitter}
          alt=""
        />
        <div className="show__circle" />
        <div className="show__gradient" />
        <div className="show__gradient show__gradient_type_mobile" />

        <div className="show__text-container">
          <div className="show__text-block">
            <p
              className="show__title"
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <p
              className="show__text"
              dangerouslySetInnerHTML={{ __html: text }}
            />
            <div className="show__datetime">
              <BoltIcon
                mainClassName="show__bolt-icon"
                fillClassName="show__bolt-icon-fill"
              />
              <p className="show__date">18.10</p>
              <div className="show__time-box">
                <p className="show__time">20:00</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Show;
