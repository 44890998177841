import { useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import './Exhibition.css';
import main from '../../assets/images/exhibition/exhibition-main.webp';
import mainMobile from '../../assets/images/exhibition/exhibition-main-mobile.webp';
import mapEN from '../../assets/images/exhibition/exhibition-map.webp';
import mapRU from '../../assets/images/exhibition/exhibition-map-ru.webp';
import logo from '../../assets/images/exhibition/logo.webp';
import { TranslationContext } from '../../assets/contexts/translationContext';
import { EN_LANGUAGE, RENT_TYPE } from '../../assets/utils/constants';
import PageHeading from '../PageHeading/PageHeading';
import { FormPopupContext } from '../../assets/contexts/formPopupContext';

function Exhibition({ lang }) {
  const { exhibition } = useContext(TranslationContext);
  const { legend } = exhibition;
  const { setIsFormPopupOpen } = useContext(FormPopupContext);
  const [, setSearchParams] = useSearchParams();

  const LEGEND = [
    {
      color: '#0089ED',
      text: `${legend.text1}`,
    },
    {
      color: '#0045CE',
      text: `${legend.text2}`,
    },
    {
      color: '#14008C',
      text: `${legend.text3}`,
    },
  ];

  function openFormPopup() {
    setIsFormPopupOpen(true);
    setSearchParams({ type: RENT_TYPE.value });
  }

  return (
    <section className="exhibition">
      <PageHeading title={exhibition.mainTitle} btnText={exhibition.btnMember} onClick={openFormPopup} titleClassName="exhibition__title">
        <img alt="" src={main} className="exhibition__heading-img" />
        <img
          alt=""
          src={mainMobile}
          className="exhibition__heading-img exhibition__heading-img_type_mobile"
        />
      </PageHeading>

      <div className="exhibition__content">
        <div className="exhibition__map-block">
          {lang === EN_LANGUAGE ? (
            <img
              key={`schema_${lang}`}
              alt=""
              src={mapEN}
              className="exhibition__img-map"
            ></img>
          ) : (
            <img
              key={`schema_${lang}`}
              alt=""
              src={mapRU}
              className="exhibition__img-map"
            ></img>
          )}
          <div>
            <div className="exhibition__legend">
              {LEGEND.map((item, i) => {
                return (
                  <div className="exhibition__legend-line">
                    <div
                      className="exhibition__legend-line__color-box"
                      style={{ backgroundColor: `${item.color}` }}
                    ></div>
                    <p>{item.text}</p>
                  </div>
                );
              })}
            </div>
            <div className="exhibition__legend-text">
              <p className="exhibition__legend-text__title">
                {exhibition.titleExhibition}
              </p>
              <p className="exhibition__legend-text__text">
                {exhibition.textExhibition}
              </p>
            </div>

            <div className="exhibition__place-box">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M9.00014 9.85712C7.57985 9.85712 6.42871 8.70598 6.42871 7.28569C6.42871 5.86541 7.57985 4.71426 9.00014 4.71426C10.4204 4.71426 11.5716 5.86541 11.5716 7.28569C11.5716 8.70598 10.4204 9.85712 9.00014 9.85712Z"
                  stroke="#FF921B"
                  stroke-width="1.6"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M9 16.7143C9 16.7143 3 11.7857 3 7.2857C3 3.97199 5.68629 1.28571 9 1.28571C12.3137 1.28571 15 3.97199 15 7.2857C15 11.7857 9 16.7143 9 16.7143Z"
                  stroke="#FF921B"
                  stroke-width="1.6"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <div className="exhibition__place-box__text">
                <p className="exhibition__legend-text__rixos">
                  {exhibition.placeTextRixos}
                </p>
                <p className="exhibition__legend-text__kz">
                  {exhibition.placeTextKz}
                </p>
              </div>
            </div>
          </div>
        </div>
        <img alt="" src={logo} className="exhibition__logo"></img>
      </div>
    </section>
  );
}

export default Exhibition;
