import { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import './Input.css';
import { SelectIcon, SmallArrowIcon } from '../../assets/icons/icons';

const dropdownVariants = {
  open: {
    clipPath: 'inset(0% 0% 0% 0% round 8px)',
    transition: {
      type: 'spring',
      bounce: 0,
      duration: 0.5,
      delayChildren: 0.2,
      staggerChildren: 0.05,
    },
  },
  closed: {
    clipPath: 'inset(0 90% 90% 10% round 8px)',
    transition: {
      type: 'spring',
      bounce: 0,
      duration: 0.3,
    },
  },
};

const itemVariants = {
  open: {
    opacity: 1,
    y: 0,
    transition: { type: 'spring', stiffness: 300, damping: 24 },
  },
  closed: { opacity: 0, y: 20, transition: { duration: 0.2 } },
};

function Input({
  name,
  type = 'text',
  value,
  onChange,
  label,
  placeholder = '',
  readOnly = false,
  isTextArea = false,
  isSelect = false,
  selectList = [],
  language,
}) {
  const inputRef = useRef(null);
  const selectDropdown = useRef();
  const [isFocused, setIsFocused] = useState(false);
  const [isSelectOpen, setSelectOpen] = useState(false);

  useEffect(() => {
    function handleOutsideClickClose(evt) {
      if (
        isSelectOpen &&
        selectDropdown.current &&
        !selectDropdown.current.contains(evt.target)
      ) {
        setSelectOpen(false);
      }
    }

    document.addEventListener('mousedown', handleOutsideClickClose);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClickClose);
    };
  });

  function handleFocused() {
    setIsFocused(true);
    inputRef.current.focus();
  }

  function handleUnfocused() {
    setIsFocused(false);
  }

  function toggleDropdown() {
    setSelectOpen((prevValue) => !prevValue);
  }

  return isSelect ? (
    <div className="input input_type_select" ref={selectDropdown}>
      <button
        className={`input__select-active ${
          isSelectOpen ? 'input__select-active_opened' : ''
        } ${readOnly ? 'input__select-active_disabled' : ''}`}
        type="button"
        onClick={toggleDropdown}
      >
        <p className="input__select-value">
          {value?.label ? value.label[language] : ''}
        </p>
        {!readOnly && (
          <motion.div
            className="input__arrow-container"
            initial={{ rotate: 0 }}
            animate={{ rotate: isSelectOpen ? 180 : 0 }}
          >
            <SmallArrowIcon
              mainClassName="input__select-arrow"
              strokeClassName="input__select-arrow-stroke"
            />
          </motion.div>
        )}
      </button>
      <div
        className={`input__dropdown ${
          isSelectOpen ? 'input__dropdown_opened' : ''
        }`}
      >
        <motion.div
          className="input__box-with-dropdown"
          initial={false}
          animate={isSelectOpen ? 'open' : 'closed'}
        >
          <motion.div
            variants={dropdownVariants}
            style={{ pointerEvents: isSelectOpen ? 'auto' : 'none' }}
            className="input__dropdown-container"
          >
            <ul className="input__dropdown-list">
              {selectList?.map((item, i) => (
                <motion.li
                  className={`input__dropdown-item ${
                    item.value === value?.value
                      ? 'input__dropdown-item_selected'
                      : ''
                  }`}
                  variants={itemVariants}
                  key={i}
                >
                  <button
                    className="input__dropdown-btn"
                    type="button"
                    onClick={() => {
                      if (readOnly) return;
                      toggleDropdown();
                      onChange({
                        target: {
                          value: item,
                          name: name,
                        },
                      });
                    }}
                  >
                    <p className="input__select-value">
                      {item.label[language]}
                    </p>
                    {item.value === value?.value && (
                      <motion.div
                        className="input__dropdown-check"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                      >
                        <SelectIcon
                          mainClassName="input__dropdown-check-icon"
                          strokeClassName="input__dropdown-check-icon-stroke"
                        />
                      </motion.div>
                    )}
                  </button>
                </motion.li>
              ))}
            </ul>
          </motion.div>
        </motion.div>
      </div>
    </div>
  ) : (
    <div
      className={`input ${isFocused ? 'input_focused' : ''}`}
      onClick={handleFocused}
    >
      <label className="input__label" htmlFor={name}>
        {label}
      </label>

      {isTextArea ? (
        <textarea
          ref={inputRef}
          className="input__input"
          id={name}
          name={name}
          placeholder={placeholder}
          value={value || ''}
          onChange={onChange}
          onFocus={handleFocused}
          onBlur={handleUnfocused}
          readOnly={readOnly}
        />
      ) : (
        <input
          ref={inputRef}
          className="input__input"
          id={name}
          name={name}
          placeholder={placeholder}
          type={type}
          value={value || ''}
          onChange={onChange}
          onFocus={handleFocused}
          onBlur={handleUnfocused}
          readOnly={readOnly}
        />
      )}
    </div>
  );
}

export default Input;
