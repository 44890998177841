export const EN_TRANSLATION = {
  header: {
    join: 'Join Now',
    location: {
      place: 'Rixos Water World Aktau',
      city: 'Aktau, Kazakhstan',
    },
  },

  footer: {
    text: 'Central Asia Gambling and Betting Summit',
    date: '17-18 October 2024',
  },

  main: {
    heading: {
      month: 'October',
      title: 'Central Asia Gambling and Betting Summit',
      subtitle: 'The first world-class gaming event ever held in the region',
      links: {
        about: 'About the Summit',
        contact: 'Contacts',
      },
    },

    join: {
      title: 'Join',
      text: 'leading industry companies at the Central Asia Betting and Gambling Summit!',
      numbers: {
        delegates: 'Delegates',
        affiliates: 'Affiliates',
        speakers: 'Speakers',
        exhibitors: 'Exhibitors',
      },
      buttons: {
        becomePartner: 'Become Partner',
        buyTicket: 'Buy Ticket',
      },
    },

    place: {
      title: 'Rixos Water World Aktau',
      text: 'Lorem ipsum dolor sit amet consectetur. Nulla maecenas vulputate montes pellentesque convallis ligula amet elementum. Sapien facilisis augue euismod dictum. Elementum eget est lobortis aenean.',
      buttons: {
        book: 'Book',
        partners: 'All accommodation partners',
      },
    },

    sponsorship: {
      title: 'Sponsorship Opportunities&nbsp;—',
      text: 'Elevate your brand with our top partnership. Enjoy prime visibility through exclusive exhibition space, premium advertising, and speaking opportunities. Your&nbsp;logo will be featured across all event platforms, ensuring maximum exposure and influence.',
      btnText: 'Details',
    },

    leader: {
      title: 'Kazakhstan as a regional leader in the gambling industry',
      join: 'Join Now',
      cards: [
        {
          title: 'Special<br />Play Areas',
          text: 'The introduction of special zones for gambling in Almaty and Akmola region makes it possible to concentrate and control the business. This creates a favorable environment for investors &&nbsp;operators, taking into account the experience and positive aspects of gaming zones using the example of developed countries.',
        },
        {
          title: 'Economic<br />Height',
          text: `Gambling can become a powerful tool for developing regional economies. Gambling contributes to the development of related industries, such as advertising, IT, and payment systems. Betting has a positive impact on achieving gender balance in sports and its development. Tax revenues from gambling increase the country's budget.`,
        },
        {
          title: 'Responsible<br />Game',
          text: 'Kazakhstan can set the tone for business development by adhering to the principles of responsible gaming.',
        },
        {
          title: 'Attraction<br />Tourists',
          text: 'The development of infrastructure, hotels and entertainment complexes will attract foreign players. Kazakhstan can become a&nbsp;popular destination for gaming tourism.',
        },
      ],
    },

    about: {
      title: 'Conference',
      text: 'Responsible Gambling and Opening New Specialized Regions for Gambling Business in Kazakhstan.<br />Prospects for Gambling and Betting Market Development in Uzbekistan after Legalization in 2025',
      btnText: 'About Conference',
    },

    show: {
      title: 'Exciting show also awaits you!',
      text: 'The tournament will feature top athletes from Central Asia&nbsp;& other parts of the world, including Brazil.',
    },

    partners: {
      title: 'Partners',
      general: 'General Partner',
      media: 'Media Partner',
      generalMedia: 'General Media Partner',
      partner: 'Partner',
      btnText: 'All partners',
    },
  },

  conference: {
    heading: {
      title: 'About the Conference',
    },

    info: {
      title:
        'Responsible Gambling &&nbsp;Opening New Specialized Regions for Gambling Business in Kazakhstan',
      text: 'Prospects for Gambling and Betting Market Development in Uzbekistan after Legalization&nbsp;in&nbsp;2025',
      goals: {
        title: 'Our Goal:',
        text: [
          'To explore the future of the gambling business in Kazakhstan, Uzbekistan and&nbsp;more generally in Central Asia, to discuss current issues and promote the responsible gambling and to highlight the advantages and benefits of opening new specialized regions for the industry and the state, and to contribute to the development of effective government policies and market structures.',
          'Relevant ministries and regulators of Kazakhstan and Uzbekistan have been invited to discuss legislation, regulations and measures to&nbsp;ensure responsible gambling. Gambling operators will share their experiences and best practices.',
        ],
      },
    },

    speakers: {
      our: 'OUR',
      title: 'Speakers',
      agenda: 'Agenda',
      card: {
        title: 'Become a&nbsp;speaker',
        text: 'Lorem ipsum dolor sit amet consectetur. Nulla maecenas vulputate montes pellentesque convallis ligula amet elementum. Sapien facilisis augue euismod dictum.',
        btnText: 'Fill out the form',
      },
      speakers: [
        {
          name: 'Siddharth Gupta',
          job: 'CEO of SilverAce Media,&#182;Estonia',
          description:
            'Technology innovation: SEO and e-&#x2060;commerce. Affiliates. Sports development through betting companies. Gambling Advertising.',
        },
        {
          name: 'Ilya Machavariani',
          job: 'CEO and Senior Partner&#182;of&nbsp;4H Agency',
          description: 'Legislation and regulation.',
        },
        {
          name: 'Norbert Rubicsek',
          job: 'Director of the Sport Integrity&#182;Group',
          description:
            'Sports and technology. Sports development through betting companies.',
        },
        {
          name: 'Mikheil Demetrashvili',
          job: 'CEO,&#182;Leader Pay',
          description: 'Cryptocurrencies and digital assets',
        },
        {
          name: 'Davit Kikvidze',
          job: 'Head of Fintech Association of&nbsp;Georgia, World Bank expert',
          description: 'Cryptocurrencies and digital assets.',
        },
        {
          name: 'George Mamulaishvili',
          job: 'Head of the Georgian Gambling&#182;Association',
          description: 'Legislation and regulation.<br />Responsible gambling.',
        },
        {
          name: 'Ninia Chkheidze',
          job: 'Chief Operations&#182;Manager',
          description:
            'Utilizing consumer insights & innovative technologies for targeted marketing&&nbsp;enhanced customer engagement.',
        },
        {
          name: 'Makhanalin Bolat',
          job: 'President of the “Support of&#182;National Sports” Foundation',
          description: 'Sport and technology.',
        },
        {
          name: 'John Bamidele',
          job: 'ICE Ambassador&#182;for Africa',
          description:
            'X-raying Africa Gambling industry, its&nbsp;potentials, challenges and similarities with&nbsp;the&nbsp;Asian gambling market.',
        },
        {
          name: 'Yaroslav Kozliuk',
          job: 'Project Manager&#182;Casino Eclipse',
          description: 'Development of gambling zones.',
        },
        {
          name: 'Ivan Kurochkin',
          job: 'Partner and Head of Eastern&#182;Europe Department of 4H&#182;Agency',
          description: 'Responsible gambling.',
        },
        {
          name: 'Lasha Shikhashvili',
          job: 'Conference moderator.&#182;Business consultant. Gambling Business Expert',
          description:
            'Responsible gambling as a basis of&nbsp;values for modern casino operator.&nbsp;IT tools for RG.',
        },
        {
          name: 'Giga Vashakidze',
          job: 'Business Development Lead&#182;at Leadership Scanner –&#182;Introducing innovative',
          description:
            'Organizational development, technologies and innovations. SEO and e-commerce.',
        },
      ],
    },

    opportunities: {
      title: 'Exclusive opportunities',
      btnText: 'Attend',
      cards: [
        {
          title: 'The importance<br />of players’ values',
          text: 'Players’ values influence their decisions in gambling, such as entertainment, social interaction and the pursuit of winning. Gambling operators must take these values into account to create a personalized and enjoyable experience. Responsible gaming and values are interconnected, and maintaining a balance between entertainment and common sense allows players to enjoy gambling without risk.',
        },
        {
          title: 'Opening new regions for&nbsp;the&nbsp;gambling business',
          text: 'Analysis of the potential for gambling development in Kazakhstan’s special economic zones. Benefits for investors and operators in entering new markets. Opportunities for tourism development and attracting foreign players.',
        },
        {
          title: 'Responsible<br />Gambling',
          text: 'Discussion of the principles of responsible gambling and the role of regulators and operators in enforcing it. Presentation of tools and techniques to protect players from problematic behaviour.',
        },
        {
          title: 'Legislative updates and&nbsp;policies',
          text: 'Based on the best international practices, discussion on the existing legislation, its negative aspects and proposals for its improvement, which will contribute to improving the working conditions for operators and attracting additional investment for the development of the industry.',
        },
        {
          title: 'Benefits for tourism development',
          text: 'Gambling can be a powerful tool for developing gaming tourism by attracting foreign players. Taxes and licenses will increase the country’s budget and stimulate the development of hotels, restaurants and entertainment complexes.',
        },
        {
          title: 'Round tables<br />and Q&A sessions',
          text: 'Participants will have the opportunity to discuss in depth the specific issues raised in the panels and ask questions to experts.',
        },
        {
          title: 'The role of betting<br />in sports development',
          text: 'Today, betting plays an important role in the development of sports in the country. The sponsorship of sporting events and athletes, gives them the opportunity to compete on the international stage and represent their country. The panel will discuss the pros and cons of gambling involvement in sport, the fair play of teams and methods to improve accountability in sport.',
        },
        {
          title: 'Compliance<br />and Anti-Money Laundering',
          text: 'Anti-money laundering is an important component of gambling transparency. As it becomes more attractive, it enhances the image and protects consumers.',
        },
      ],
    },
  },

  agenda: {
    mainTitle: `Agenda for</br>the conference`,
    mainTitleMobile: `Agenda for the conference`,
    btnTicket: 'Buy a Ticket',
    dayText: 'Day',
    speakersTitle: 'Speakers',
    timetable: {
      dayFirst: [
        {
          time: '08:30 – 09:30',
          place: '',
          text: `Arrival of Delegates. 
          Bus transfer from the city to the hotel`,
          subtext: [],
          speakers: []
        },
        {
          time: '09:30 – 10:00',
          place: 'Hotel Lobby, Conference Hall',
          text: `Delegate Registration. 
          Welcome coffee and drinks.`,
          subtext: [],
          speakers: []
        },
        {
          time: '10:00 – 10:20',
          place: 'Conference Hall',
          text: `Conference Opening. 
          Welcome Address.`,
          subtext: [],
          speakers: []
        },
        {
          time: '11:00 – 12:30',
          place: 'Conference Hall',
          text: 'Regulation and compliance.',
          subtext: [
            'Regulation in Kazakhstan, Uzbekistan, Kyrgyzstan, & Georgia.',
            'Comparison of legislative frameworks in CIS countries, post-Soviet region, & the Balkans.',
            'Updates in legislation and taxation, regulation & development of the gambling industry, issues and improvements, taxation, and administrative systems.'
          ],
          speakers: ['George Mamulaishvili', 'Ilya Machavariani', 'Representatives of the Ministry of Tourism and Sports', 'Ministry of National Economy', 'Ministry of Finance (tbc)']
        },
        {
          time: '12:30 – 12:45',
          place: 'Conference Hall',
          text: 'X-raying Africa gambling industry, its potentials, challenges, and similarities with the Asian gambling market',
          subtext: [],
          speakers: ['John (Adeolu) Bamidele']
        },
        {
          time: '12:45 – 13:15',
          place: 'Conference Hall',
          text: `The importance of the gambling business for economic and tourism development in Central Asia

          Panel discussion on economic and tourism development and the benefits of establishing new zones.`,
          subtext: [`Participants will learn how to effectively apply responsible gambling tools and how to balance government regulation and business implementation to grow the industry while ensuring quality government regulation`],
          speakers: ['Yaroslav Kozliuk', 'Representative of the Kazakhstan Tourism Association (tbc)']
        },
        {
          time: '13:15 – 13:45',
          place: 'Conference Hall',
          text: `Responsible Gambling

          Panel discussion on implementing responsible gambling tools, their benefits to society and the business sector, and balancing government regulation with business implementation.`,
          subtext: [`Participants will learn how to effectively apply responsible gambling tools and how to balance government regulation and business implementation to grow the industry while ensuring quality government regulation`],
          speakers: ['George Mamulaishvili', 'Lasha Shikhashvili', 'Ivan Kurochkin']
        },
        {
          time: '13:45 – 14:15',
          place: 'Conference Hall',
          text: `Code of conduct for players or how to combat Gambling addiction

          Discussion on gambling addiction issues, solutions, and ways to reduce gambling addiction through awareness raising and the protection of vulnerable groups.`,
          subtext: [`Participants will discuss the problems of gambling addiction, ways to address and reduce the number of gambling addicts through information and awareness-raising, and the protection of minors and vulnerable groups`],
          speakers: ['Lasha Shikhashvili', 'George Mamulaishvili', 'Psychologist (tbc)', 'Representative of the Rehabilitation Center (tbc)']
        },
        {
          time: '14:15 – 15:15',
          place: 'Conference Hall',
          text: `Break`,
          subtext: [],
          speakers: []
        },
        {
          time: '15:15 – 16:00',
          place: 'Conference Hall',
          text: `Weighing of MMA Fighters`,
          subtext: [],
          speakers: []
        },
        {
          time: '16:00 – 16:30',
          place: 'Conference Hall',
          text: `AML & KYC – Combating shadow money flows
          
          Discussion on global methods for detecting illegal financial flows and the importance of KYC systems in the gambling industry.`,
          subtext: [`Participants will discuss what methods and practices exist around the world to detect illicit financial transactions and the importance of the role of KYC (Know Your Customer) systems in the gaming industry. The benefits to governments and companies of implementing such systems and controls`],
          speakers: ['Representatives of the Financial Monitoring Agency (tbc)', 'Academy of Financial Monitoring (tbc)']
        },
        {
          time: '16:30 – 17:00',
          place: 'Conference Hall',
          text: `Organizational development, technologies and innovations. SEO and E-Commerce
          
          Panel Discussion`,
          subtext: [],
          speakers: ['Siddharth Gupta', 'Giga Vashakidze']
        },
        {
          time: '17:00 – 17:30',
          place: 'Conference Hall',
          text: `SEO and affiliate programs
          
          Panel Discussion`,
          subtext: [],
          speakers: ['Siddharth Gupta']
        },
        {
          time: '17:30 – 18:00',
          place: 'Conference Hall',
          text: `Cryptocurrencies and digital assets and electronic payment systems
          
          Panel Discussion`,
          subtext: [],
          speakers: ['Davit Kikvidze', 'Mikheil Demetrashvili', 'Representative of the International Financial Center "Astana" (tbc)']
        },
        {
          time: '18:00 – 18:30',
          place: 'Conference Hall',
          text: `WEB3 Gaming: Game on
          
          Discussion on WEB3 technologies and their impact on the gaming industry`,
          subtext: [],
          speakers: ['Nikita Velichko', 'Egor Rozinskii']
        },
        {
          time: '18:30 – 18:40',
          place: 'Conference Hall',
          text: `Closing of the First Day`,
          subtext: [],
          speakers: []
        },
        {
          time: '19:10',
          place: 'Hotel Lobby',
          text: `Bus transfer to the city`,
          subtext: [],
          speakers: []
        },
        {
          time: '19:00 – 21:00',
          place: 'Restaurant Mermaid',
          text: `Closed Dinner Meeting`,
          subtext: [],
          speakers: []
        },
        {
          time: '21:00 – 00:00',
          place: 'Hotel Lobby',
          text: `Networking Drinks (Party)`,
          subtext: [],
          speakers: []
        },
      ],
      daySecond: [
        {
          time: '09:00 – 10:00',
          place: 'Conference Hall',
          text: `Arrival of Delegates. 
          Coffee and Drinks. 
          Bus transfer from the city to the hotel`,
          subtext: [],
          speakers: []
        },
        {
          time: '10:00 – 11:00',
          place: 'Conference Hall',
          text: `Sport and technology
          
          Panel Discussion`,
          subtext: [],
          speakers: ['Makhanalin Bolat', 'Norbert Rubicsek']
        },
        {
          time: '11:00 – 12:00',
          place: 'Conference Hall',
          text: `Sport development with the help of betting companies and sports media
          
          Panel split into two parts:`,
          subtext: [`Presentation by the Director of the Sport Integrity Group`, `Panel Discussion`],
          speakers: ['Makhanalin Bolat', 'Norbert Rubicsek']
        },
        {
          time: '12:00 – 12:30',
          place: 'Conference Hall',
          text: `Advertising activities in the Gambling business
          
          Panel discussion on advertising technologies, innovations, marketing strategies, and behavioral science for player retention`,
          subtext: [],
          speakers: ['Siddharth Gupta', 'Ninia Chkheidze']
        },
        {
          time: '12:30',
          place: 'Conference Hall',
          text: `Closing of the Conference and Exhibition`,
          subtext: [],
          speakers: []
        },
        {
          time: '13:30',
          place: 'Hotel Lobby',
          text: `Bus transfer to the city`,
          subtext: [],
          speakers: []
        },
        {
          time: '20:00 – 22:00',
          place: 'Conference Hall',
          text: `MMA Tournament`,
          subtext: [],
          speakers: []
        },
        // ... Add the rest of the events for daySecond
      ]
    },
  },

  exhibition: {
    mainTitle: `iGaming &&nbsp;Betting</br>Exhibition`,
    btnMember: 'Become a Member',
    titleExhibition: `Exhibition`,
    textExhibition: ` Responsible Gambling and Opening New Specialized Regions for Gambling Business in Kazakhstan.
Prospects for Gambling and Betting Market Development in Uzbekistan after Legalization in 2025`,
    placeTextRixos: 'Rixos Water World Aktau',
    placeTextKz: 'Aktau, Kazakhstan',
    legend: {
      text1: 'Sponsor Stands',
      text2: 'Free Stands',
      text3: 'Occupied Stands',
    },
  },

  sponsors: {
    title: 'Conference<br />Sponsors',
    btnText: 'Become a Sponsor',
    quantity: 'Quantity',
    benefits: 'Sponsorship Benefits',
    obligations: 'Sponsorship Obligations',
    types: [
      {
        typeBtn: 'Platinum',
        title: 'Platinum Sponsor',
        benefits: [
          'Exhibition area for the Platinum Sponsor of up to 40 square metres.',
          'Platinum Sponsor’s information and advertising module in the official Summit programme (4th page of the cover + 1 colour page).',
          'Welcoming address by the CEO of the Platinum Sponsor company as part of the official Summit programme (1 page).',
          'Participation and welcome speech by the Platinum Sponsor’s company representative at the official opening ceremony of the event (if held).',
          'Provision of a conference room (up to 1 hour) for the Platinum Sponsor’s company presentation during the conference.',
          'Accommodation for 3 representatives of the Platinum Sponsor in the RIXOS Water World Aktau Hotel from 16 to 19 October 2024 for the duration of the Summit.',
          'Invitation tickets for 3 representatives of the Platinum Sponsor to the Gala Dinner on the first day of the Summit.',
          'Distribution of the Platinum Sponsor’s promotional and informational materials (brochures, leaflets and other printed materials) at the Information Desk during the Summit.',
          'Platinum Sponsor’s banner (5m x 2.5m) in the exhibition area during the Summit.',
          'Platinum Sponsor banner (2m x 1m) in the conference hall during the conference.',
          'Daily broadcast of the Platinum Sponsor’s promotional video (up to 1 minute) on the screens in the pavilion at least 10 times per hour during the Summit.',
          'Platinum Sponsor logo on event invitation cards.',
          'Platinum Sponsor logo on official event badges.',
          'Platinum Sponsor’s logo on event promotional materials, including business publications, trade journals, directories, websites, banners, etc.',
          'Platinum Sponsor logo on the front page of the official Summit programme.',
          'Information about the Platinum Sponsor in event newsletters distributed via targeted mailings, emails, etc.',
          'Information about the Platinum Sponsor on the official Summit website with a link to the company’s website.',
          'Information about the Platinum Sponsor’s news and events on the event’s social networks (if material is provided in time).',
          'Publication of an exclusive interview with representatives of the Platinum Sponsor prior to the event on relevant news portals such as www.CasinoBeats.com.',
          'Regular posts on the CAGBS LinkedIn group mentioning the Platinum Sponsor.',
          'Platinum Sponsor’s logo on the CAGB Summit advertisement on a 4m x 2m light box at Aktau International Airport in the arrivals hall or on a 3.2m x 3m light box in the general hall in the arrivals area.',
        ],
        obligations: [
          'Placement of information about the Summit (banner, announcement) on the Platinum Sponsor’s official website with a link to the Summit website no later than 1.5 months before the event.',
          'Placement of information about the Summit on the Platinum Sponsor’s social networks no later than 1.5 months before the event.',
          'Dissemination of information about the Summit to the Platinum Sponsor’s partners and customers through a targeted email mailing (if such a mailing is undertaken) with a brief report of the mailing.',
          'The widest possible dissemination of information about the Platinum Sponsor’s status as a Platinum Sponsor of the event by the Platinum Sponsor’s company representatives.',
        ],
      },
      {
        typeBtn: 'Gold',
        title: 'Gold Sponsor',
        benefits: [
          'Provision of exhibition area for the Gold Sponsor up to 32 square metres.',
          'Information and advertising module of the Gold Sponsor in the official programme of the Summit (3rd page of the cover + 1 colour page).',
          'Greeting speech of the CEO of the Gold Sponsor’s company in the official programme of the Summit (1 page).',
          'Participation and welcome speech of the Gold Sponsor’s company representative at the Official Opening Ceremony of the event (if held).',
          'Provision of a conference room (up to 1 hour) for the presentation of the Gold Sponsor’s company during the conference.',
          'Accommodation in the hotel RIXOS Water World Aktau for 2 representatives of the Gold Sponsor from 16 to 19 October 2024 for the duration of the Summit.',
          'Provision of invitation tickets for 2 representatives of the Gold Sponsor to the Gala Dinner on the first day of the Summit.',
          'Distribution of promotional and informational materials (booklets, brochures and other printed materials) of the Gold Sponsor at the information desk during the Summit.',
          'Banner (4m x 2.5m) of the Gold Sponsor on the territory of the exhibition during the Summit.',
          'Banner (2m x 1m) of the Gold Sponsor in the conference hall during the conference.',
          'Daily broadcasting of the advertising video (up to 1 minute) of the Gold Sponsor on the screen in the pavilion during the summit at least 7 times per hour.',
          'Logo of the Gold Sponsor on the invitation tickets of the event. The logo of the Gold Sponsor on the official badges of the event.',
          'Logo of the Gold Sponsor in advertising materials of the event, placed in business publications, specialised publications, directories, websites, banners, etc.',
          'Logo of the Gold Sponsor on the first page of the official programme of the Summit.',
          'Information about the Gold Sponsor in newsletters about the event, distributed by targeted mailing by post, e-mail.',
          'Information about the Gold Sponsor on the official website of the Summit with a link to the company’s website.',
          'Information about the novelties and events of the Gold Sponsor in the social networks of the event (if the material is provided in time).',
          'Exclusive interview with representatives of the Gold Sponsor before the event on the relevant news portals, such as www.CasinoBeats.com.',
          'Periodic publication in CAGBS LinkedIn group with mentioning of the Gold Sponsor.',
          'Placement of the logo of the Gold Sponsor on the CAGB Summit advertising on a lightbox (size 4m x 2m) at the Aktau International Airport in the arrivals hall or on a lightbox (size 3.2m x 3m) in the general hall in the arrivals area.',
        ],
        obligations: [
          'Place information about the Summit (banner, announcement) on the Gold Sponsor’s official website with a link to the Summit website no later than 1.5 months before the event.',
          'Place information about the Summit on the Gold Sponsor’s social networks no later than 1.5 months before the event.',
          'Dissemination of information about the Summit to the Gold Sponsor’s partners and customers via a targeted email mailing (if such a mailing is carried out) with a brief report on the mailing.',
          'Maximum dissemination of information about the Gold Sponsor’s company status by representatives of the Gold Sponsor’s company.',
        ],
      },
      {
        typeBtn: 'Silver',
        title: 'Silver Sponsor',
        benefits: [
          'Provision of exhibition area for the Silver Sponsor up to 24 square metres.',
          'Information and advertising module of the Silver Sponsor in the official programme of the Summit (2 colour pages).',
          'Greeting speech of the CEO of the Silver Sponsor’s company in the official programme of the Summit (1 page).',
          'Participation and welcome speech of the Silver Sponsor’s company representative at the Official Opening Ceremony of the event (if held).',
          'Provision of a conference room (up to 1 hour) for the presentation of the Silver Sponsor’s company during the conference.',
          'Accommodation in the hotel RIXOS Water World Aktau for 1 representatives of the Silver Sponsor from 16 to 19 October 2024 for the duration of the Summit.',
          'Provision of invitation tickets for 1 representatives of the Silver Sponsor to the Gala Dinner on the first day of the Summit.',
          'Distribution of promotional and informational materials (booklets, brochures and other printed materials) of the Silver Sponsor at the information desk during the Summit.',
          'Banner (3m x 2.5m) of the Silver Sponsor on the territory of the exhibition during the Summit.',
          'Banner (2m x 1m) of the Silver Sponsor in the conference hall during the conference.',
          'Daily broadcasting of the advertising video (up to 1 minute) of the Silver Sponsor on the screen in the pavilion during the summit at least 5 times per hour.',
          'Logo of the Silver Sponsor on the invitation tickets of the event. The logo of the Silver Sponsor on the official badges of the event.',
          'Logo of the Silver Sponsor in advertising materials of the event, placed in business publications, specialised publications, directories, websites, banners, etc.',
          'Logo of the Silver Sponsor on the first page of the official programme of the Summit.',
          'Information about the Silver Sponsor in newsletters about the event, distributed by targeted mailing by post, e-mail.',
          'Information about the Silver Sponsor on the official website of the Summit with a link to the company’s website.',
          'Information about the novelties and events of the Silver Sponsor in the social networks of the event (if the material is provided in time).',
          'Periodic publication in CAGBS LinkedIn group with mentioning of the Silver Sponsor.',
          'Publication of an exclusive interview with Silver Sponsor representatives prior to the event on relevant news portals such as www.CasinoBeats.com.',
          'Placement of the logo of the Silver Sponsor on the CAGB Summit advertising on a lightbox (size 4m x 2m) at the Aktau International Airport in the arrivals hall or on a lightbox (size 3.2m x 3m) in the general hall in the arrivals area.',
        ],
        obligations: [
          'Place information about the Summit (banner, announcement) on the Silver Sponsor’s official website with a link to the Summit website no later than 1.5 months before the event.',
          'Place information about the Summit on the Silver Sponsor’s social networks no later than 1.5 months before the event.',
          'Dissemination of information about the Summit to the Silver Sponsor’s partners and customers by targeted email distribution (if such a distribution is carried out) with a brief report on the distribution.',
          'The widest possible dissemination of information about the Silver Sponsor’s status as a Silver Sponsor of the event by representatives of the Silver Sponsor’s company.',
        ],
      },
      {
        typeBtn: 'Conference',
        title: 'Conference Sponsor',
        benefits: [
          'Branding of the arch at the entrance to the conference area.',
          'Regular publication on the CAGBS LinkedIn group with mention of the conference sponsor.',
          'Provision of a conference room (up to 30 minutes) for the Conference Sponsor’s company presentation during the conference.',
          'Information and promotional module for the Conference Sponsor in the official Summit programme (1 colour page).',
          'Distribution of the Conference Sponsor’s promotional and information materials (leaflets, brochures and other printed publications) at the registration desk during the Summit.',
          '2 conference sponsor banners (2m x 1m) in the conference hall during the conference.',
          'Daily broadcast of the Conference Sponsor’s promotional video (up to 30 seconds) on the screen in the pavilion at least 5 times per hour during the Summit.',
          'Logo of the Conference Sponsor on the support page in the official Summit programme.',
          'Information about the Conference Sponsor in event newsletters distributed by targeted mailing, e-mail.',
          'Information about the Conference Sponsor on the official Summit website with a link to the company’s website.',
          'Information about the conference sponsor’s news and events on the event’s social networks (if material is provided in time).',
        ],
        obligations: [
          'Placement of information about the Summit (banner, announcement) on the official website of the Conference Sponsor with a link to the Summit website no later than 1.5 months before the event.',
          'Place information about the Summit on the Conference Sponsor’s social networks no later than 1.5 months before the event.',
          'Dissemination of information about the Summit to partners and clients of the Conference Sponsor by targeted email distribution (if such distribution is undertaken) with a brief report of the distribution.',
          'The widest possible dissemination of information about the company’s status as a Conference Sponsor of the event by representatives of the Conference Sponsor’s company.',
        ],
      },
      {
        typeBtn: 'Coffee Break',
        title: 'Coffee Break and Lounge Area Sponsor',
        benefits: [
          'Branding of the Coffee Break and Lounge areas according to the sponsor’s layout.',
          'Provision by the Coffee Break and Lounge Area Sponsor of branded clothing for service staff, branded tableware (cups, glasses, plates, etc.).',
          'Distribution of the Coffee Break and Lounge Area Sponsor’s promotional and informational materials (leaflets, brochures and other printed publications) at the information desk during the Summit.',
          'Coffee Break and Lounge Area Sponsor banner (2m x 1m) in the main foyer in the registration area during the conference.',
          'Coffee Break and Lounge Area Sponsor promotional video (up to 30 seconds) to be shown on the Pavilion screen at least 5 times per hour during the Summit.',
          'Logo of the Coffee Break and Lounge Area Sponsor on the support page of the official Summit programme.',
          'Information about the Coffee Break and Lounge Area Sponsor in the event newsletters distributed by targeted mailing, e-mail.',
          'Information about the Coffee Break and Lounge Area Sponsor on the official Summit website with a link to the company’s website.',
          'Information about the Coffee Break and Lounge Area Sponsor’s new products and events on the event’s social networks (if material is provided in time).',
          'Regular posting on the CAGBS LinkedIn group with mention of the Coffee Break and Lounge Area Sponsor.',
        ],
        obligations: [
          'Provision by the Coffee Break and Lounge Area Sponsor of branded tableware and uniforms for staff no later than 10 days before the event (quantity to be agreed separately).',
          'Placement of information about the Summit (banner, announcement) on the official website of the Coffee Break and Lounge Area Sponsor with a link to the Summit website no later than 1.5 months before the event.',
          'Placement of information about the Summit on the social networks of the Coffee Break and Lounge Area Sponsor no later than 1.5 months before the event.',
          'Dissemination of information about the Summit to partners and clients of the Coffee Break and Lounge Area Sponsor by targeted email distribution (if such distribution is undertaken) with a brief report on the distribution.',
          'Maximum distribution by the Coffee Break and Lounge Area Sponsor’s company representatives.',
        ],
      },
      {
        typeBtn: 'Registration',
        title: 'Registration Sponsor',
        benefits: [
          'Branding of the registration area according to the Registration Sponsor’s layout.',
          'Provision of branded clothing by the Registration Sponsor to the registration staff.',
          'Distribution of Sponsor’s promotional and informational materials (brochures, leaflets and other printed materials) at the registration desk during the Summit.',
          'Banner (2m x 1m) of 2 pieces of the Registration Sponsor in the main lobby in the registration area during the conference.',
          'Daily broadcasting of a promotional video (up to 30 seconds) of the Registration Sponsor on the screen in the pavilion during the Summit at least 5 times per hour.',
          'The logo of the Registration Sponsor on the support page in the official Summit programme.',
          'Information about the Registration Sponsor in the event newsletters distributed by targeted mailing, e-mail.',
          'Information about the Registration Sponsor on the official Summit website with a link to the company’s website.',
          'Information about new products and events of the Registration Sponsor in the social networks of the event (if the material is provided in time).',
          'Periodic publication in CAGBS LinkedIn group with mentioning of the Registration Sponsor.',
        ],
        obligations: [
          'Placement of information about the Summit (banner, announcement) on the official website of the Registration Sponsor with a link to the Summit website no later than 1.5 months prior to the event.',
          'Placement of information about the Summit in social networks of the Registration Sponsor not later than 1.5 months before the event.',
          'Dissemination of information about the Summit to partners and clients of the Registration Sponsor by targeted e-mail distribution (if such distribution will be carried out) with a brief report on the distribution.',
          'Maximising dissemination of information by the Registration Sponsor’s company representatives about the company’s status as Registration Sponsor of the event.',
        ],
      },
      {
        typeBtn: 'Badge',
        title: 'Badge Sponsor',
        benefits: [
          'Badge Sponsor’s logo on official event badges.',
          'Badge Sponsor’s logo on lanyards for official event badges (lanyards provided by sponsor).',
          'Distribution of the Badge Sponsor’s promotional materials (leaflets, brochures and other printed publications) at the Information Desk during the Summit.',
          'Badge Sponsor’s banner (2m x 1m) in the lobby during the event.',
          'Badge Sponsor’s promotional video (up to 30 seconds) to be shown on the Pavilion screen at least 5 times per hour during the Summit.',
          'Badge Sponsor logo on the support page in the official Summit programme.',
          'Information about the Badge Sponsor in event information letters distributed by post, e-mail.',
          'Information about the Badge Sponsor on the official Summit website with a link to the company’s website.',
          'Information about the Badge Sponsor’s news and events on the event’s social networks (if material is provided in a timely manner).',
          'Regular posts on the CAGBS LinkedIn group mentioning the Badge Sponsor.',
        ],
        obligations: [
          'Provision by the Badge Sponsor of branded lanyards for official event badges no later than 15 days before the event (number of lanyards to be agreed in addition).',
          'Posting of information about the Summit (banner, announcement) on the Badge Sponsor’s official website with a link to the Summit website no later than 1.5 months before the event.',
          'Posting information about the Summit on the Badge Sponsor’s social networks no later than 1.5 months before the event.',
          'Dissemination of information about the Summit to the Badge Sponsor’s partners and customers by targeted email distribution (if such a distribution is carried out) with a brief report on the distribution.',
          'The widest possible distribution by representatives of the Badge Sponsor company of information about the company’s status as a Badge Sponsor of the event.',
        ],
      },
      {
        typeBtn: 'Souvenir',
        title: 'Souvenir Sponsor',
        benefits: [
          'Distribution by the Souvenir Sponsor of approved branded souvenir products (bags, pens, notebooks, souvenirs, etc.) to CAGB Summit attendees and visitors during the Summit.',
          'Distribution of the Souvenir Sponsor’s promotional and informational materials (booklets, brochures and other printed publications) at the Information Desk during the Summit.',
          'Souvenir Sponsor’s banner (2m x 1m) in the lobby during the event.',
          'Souvenir Sponsor’s promotional video (up to 30 seconds) to be shown on the screen in the pavilion at least 5 times per hour during the Summit.',
          'Logo of the Souvenir Sponsor on the support page in the official Summit programme.',
          'Information about the Souvenir Sponsor in the event newsletters distributed by post and e-mail.',
          'Information about the Souvenir Sponsor on the official Summit website with a link to the company’s website.',
          'Information about new products and events from the Souvenir Sponsor on the event’s social networks (if material is provided in a timely manner).',
          'Regular postings on the CAGBS LinkedIn group mentioning of the Souvenir Sponsor.',
        ],
        obligations: [
          'Provision of branded souvenirs by the Souvenir Sponsor for participants and visitors to the CAGB Summit no later than 10 days before the event (quantity to be agreed additionally).',
          'Information about the Summit (banner, announcement) on the Souvenir Sponsor’s official website with a link to the Summit website no later than 1.5 months before the event.',
          'Posting information about the Summit on the Souvenir Sponsor’s social networks no later than 1.5 months before the event.',
          'Dissemination of information about the Summit to the Souvenir Sponsor’s partners and customers by targeted email distribution (if such a distribution is carried out) with a brief report on the distribution.',
          'The widest possible distribution of information about the company’s status as a Souvenir Sponsor of the event by representatives of the Souvenir Sponsor company.',
        ],
      },
    ],
  },

  partners: {
    mainTitle: `Conference</br>Partners`,
    btnPartner: 'Become a Partner',
    partnerList: {
      general: {
        title: 'General Parters',
        partners: {
          title1: 'Rixos Water World Aktau',
          desc1: `Lorem ipsum dolor sit amet tempus
            consectetur. Arcu ut cursus at pellentesque sit vehicula tempus nisl augue. Egestas et in elit id vulputate viverra.`,

          title2: 'CABA HUB',
          desc2: `Lorem ipsum dolor sit amet tempus
            consectetur. Arcu ut cursus at pellentesque sit vehicula tempus nisl augue. Egestas et in elit id vulputate viverra.`,

          title3: 'Rixos Water World Aktau',
          desc3: `Lorem ipsum dolor sit amet tempus
            consectetur. Arcu ut cursus at pellentesque sit vehicula tempus nisl augue. Egestas et in elit id vulputate viverra.`,

          title4: 'Rixos Water World Aktau',
          desc4: `Lorem ipsum dolor sit amet tempus
            consectetur. Arcu ut cursus at pellentesque sit vehicula tempus nisl augue. Egestas et in elit id vulputate viverra.`,

          title5: 'Rixos Water World Aktau',
          desc5: `Lorem ipsum dolor sit amet tempus
            consectetur. Arcu ut cursus at pellentesque sit vehicula tempus nisl augue. Egestas et in elit id vulputate viverra.`,
        },
      },
      media: {
        title: 'General Parters',
        partners: {
          title1: 'Rixos Water World Aktau',
          title2: 'CABA HUB',
        },
      },
    },
  },

  contacts: {
    title: 'Contacts',
    text: 'Please complete the form below<br />and we will contact you as soon as possible',
    location: {
      address: 'Zaqaria Faliashvili, 17',
      city: 'Georgia, Tbilisi',
    },
  },

  form: {
    labels: {
      name: 'Name',
      surname: 'Surname',
      email: 'E-mail',
      message: 'Message',
      agreement: 'I give my consent to the processing of&nbsp;Personal Data',
      submit: 'Send',
    },
  },
};
