import './NavBar.css';

function NavBar({ list, selected, onClick }) {
  return (
    <div className="navbar">
      <ul className="navbar__list">
        {list.map((item) => (
          <li className="navbar__item" key={item.type}>
            <button
              className={`navbar__btn ${
                item.type === selected ? 'navbar__btn_active' : ''
              }`}
              type="button"
              onClick={() => onClick(item.type)}
            >
              {item.typeBtn}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default NavBar;
