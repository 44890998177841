import { useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { TranslationContext } from '../../../assets/contexts/translationContext';
import './Speakers.css';
import bg from '../../../assets/images/conference/wave-bg.webp';
import card1 from '../../../assets/images/conference/speakers/Siddharth_Gupta.webp';
import card2 from '../../../assets/images/conference/speakers/Ilya_Machavariani.webp';
import card3 from '../../../assets/images/conference/speakers/Norbert_Rubicsek.webp';
import card4 from '../../../assets/images/conference/speakers/Mikheil_Demetrashvili.webp';
import card5 from '../../../assets/images/conference/speakers/Davit_Kikvidze.webp';
import card6 from '../../../assets/images/conference/speakers/George_Mamulaishvili.webp';
import card7 from '../../../assets/images/conference/speakers/Ninia_Chkheidze.webp';
import card8 from '../../../assets/images/conference/speakers/Makhanalin_Bolat.webp';
import card9 from '../../../assets/images/conference/speakers/John_Bamidele.webp';
import card10 from '../../../assets/images/conference/speakers/Yaroslav_Kozliuk.webp';
import card11 from '../../../assets/images/conference/speakers/Ivan_Kurochkin.webp';
import card12 from '../../../assets/images/conference/speakers/Lasha_Shikhashvili.webp';
import card13 from '../../../assets/images/conference/speakers/Giga_Vashakidze.webp';
import {
  CloudIcon,
  DownloadIcon,
  RoundArrowIcon,
} from '../../../assets/icons/icons';
import Button from '../../Button/Button';
import { AGENDA_LINK, ATTENDER_TYPE } from '../../../assets/utils/constants';
import PersonCard from './PersonCard/PersonCard';
import useWindowSize from '../../../assets/hooks/useWindowSize';
import { FormPopupContext } from '../../../assets/contexts/formPopupContext';

const photos = [
  card1,
  card2,
  card3,
  card4,
  card5,
  card6,
  card7,
  card8,
  card9,
  card10,
  card11,
  card12,
  card13,
];

function Speakers() {
  const {
    conference: {
      speakers: { our, title, agenda, card, speakers },
    },
  } = useContext(TranslationContext);
  const { setIsFormPopupOpen } = useContext(FormPopupContext);
  const { width } = useWindowSize();
  const [, setSearchParams] = useSearchParams();

  function openFormPopup() {
    setIsFormPopupOpen(true);
    setSearchParams({ type: ATTENDER_TYPE.value });
  }

  return (
    <div className="speakers">
      <div className="speakers__content">
        <ul className="speakers__list">
          <div className="speakers__title-block">
            <p className="speakers__subtitle">{our}</p>
            <p className="speakers__title">{title}</p>
            <div className="speakers__btn-block">
              <Button
                text={agenda}
                icon={
                  <RoundArrowIcon
                    mainClassName="speakers__arrow-icon"
                    fillClassName="speakers__arrow-icon-fill"
                  />
                }
                link={AGENDA_LINK}
                className="speakers__link-btn"
                isColorBtn
                isWidthRestricted
              />
              <button className="speakers__download-btn" type="button">
                <DownloadIcon
                  mainClassName="speakers__download-icon"
                  strokeClassName="speakers__download-icon-stroke"
                />
              </button>
            </div>
          </div>

          {speakers.map((item, i) => (
            <li key={i} className="speakers__item">
              <PersonCard data={{ ...item, photo: photos[i] }} />
            </li>
          ))}

          <div className="speakers__card">
            <div className="speakers__card-heading">
              <p
                className="speakers__card-title"
                dangerouslySetInnerHTML={{ __html: card.title }}
              />
              <CloudIcon
                mainClassName="speakers__card-icon"
                strokeClassName="speakers__card-icon-stroke"
              />
            </div>
            <p
              className="speakers__card-text"
              dangerouslySetInnerHTML={{ __html: card.text }}
            />
            <Button
              text={card.btnText}
              onClick={openFormPopup}
              isColorBtn
              onCardBtn={width <= 900}
            />
          </div>
        </ul>

        <img className="speakers__bg" src={bg} alt="" />
      </div>
    </div>
  );
}

export default Speakers;
