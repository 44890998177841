import { useState, useEffect } from 'react';
import { Routes, Route, useSearchParams } from 'react-router-dom';
import './App.css';
import {
  TranslationContext,
  translations,
} from '../../assets/contexts/translationContext';
import {
  VALIDE_LANG,
  EN_LANGUAGE,
  MAIN_LINK,
  ABOUT_LINK,
  AGENDA_LINK,
  EXHIBITION_LINK,
  SPONSORS_LINK,
  PARTNERS_LINK,
  CONTACTS_LINK,
} from '../../assets/utils/constants';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import Main from '../Main/Main';
import Conference from '../Conference/Conference';
import Agenda from '../Agenda/Agenda';
import Exhibition from '../Exhibition/Exhibition';
import Sponsors from '../Sponsors/Sponsors';
import Partners from '../Partners/Partners';
import Contacts from '../Contacts/Contacts';
import FormPopup from '../FormPopup/FormPopup';
import { FormPopupContext } from '../../assets/contexts/formPopupContext';

function App() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isFormPopupOpen, setIsFormPopupOpen] = useState(false);
  const [language, setLanguage] = useState(
    localStorage.getItem('language') !== null &&
      VALIDE_LANG.includes(localStorage.getItem('language'))
      ? localStorage.getItem('language')
      : EN_LANGUAGE
  );
  const lang = searchParams.get('lang');

  useEffect(() => {
    if (lang && VALIDE_LANG.includes(lang)) {
      setLanguage(lang);
      localStorage.setItem('language', lang);
    } else {
      setSearchParams((params) => {
        params.set('lang', language);
        return params;
      });
      localStorage.setItem('language', language);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  return (
    <TranslationContext.Provider value={translations[language]}>
      <FormPopupContext.Provider
        value={{ isFormPopupOpen, setIsFormPopupOpen }}
      >
        <div className="app">
          <Header {...{ language }} />
          <div className="app__container">
            <Routes>
              <Route path={MAIN_LINK} element={<Main {...{ language }} />} />
              <Route path={ABOUT_LINK} element={<Conference />} />
              <Route path={AGENDA_LINK} element={<Agenda />} />
              <Route
                path={EXHIBITION_LINK}
                element={<Exhibition lang={lang} />}
              />
              <Route path={SPONSORS_LINK} element={<Sponsors />} />
              <Route path={PARTNERS_LINK} element={<Partners />} />
              <Route path={CONTACTS_LINK} element={<Contacts />} />
            </Routes>
          </div>
          <Footer />
          <FormPopup {...{ language }} />
        </div>
      </FormPopupContext.Provider>
    </TranslationContext.Provider>
  );
}

export default App;
