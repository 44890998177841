import { useContext } from 'react';
import { TranslationContext } from '../../../assets/contexts/translationContext';
import './ConfHeading.css';
import banner from '../../../assets/images/main/heading/banner.webp';
import tennis from '../../../assets/images/main/heading/small-ball.webp';
import football from '../../../assets/images/main/heading/big-ball.webp';
import basketball from '../../../assets/images/conference/basketball.webp';

function ConfHeading() {
  const {
    conference: {
      heading: { title },
    },
  } = useContext(TranslationContext);

  return (
    <div className="conf-heading">
      <div className="conf-heading__content">
        <h2 className="conf-heading__title">{title}</h2>

        <div className="conf-heading__image-block">
          <img src={banner} alt="" className="conf-heading__image" />
          <img src={tennis} alt="" className="conf-heading__tennis" />
          <img src={football} alt="" className="conf-heading__football" />
          <div className="conf-heading__rectangle" />
          <div className="conf-heading__circle conf-heading__circle_size_small" />
          <div className="conf-heading__circle conf-heading__circle_size_big" />
        </div>
        <img src={basketball} alt="" className="conf-heading__basketball" />
      </div>
    </div>
  );
}

export default ConfHeading;
