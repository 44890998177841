import { Link } from 'react-router-dom';
import './Button.css';

function Button({
  text,
  onClick,
  link,
  href,
  icon,
  className = '',
  isColorBtn = false,
  isBigBtn = false,
  isSmallBtn = false,
  isWidthRestricted = false,
  onCardBtn = false,
}) {
  return link ? (
    <Link
      to={link}
      className={`button ${className} ${
        isColorBtn ? 'button_type_color' : ''
      } ${isBigBtn ? 'button_size_big' : ''} ${
        isWidthRestricted ? 'button_width_restricted' : ''
      } ${isSmallBtn ? 'button_size_small' : ''} ${
        onCardBtn ? 'button_type_card' : ''
      }`}
    >
      {text}
      {icon ? icon : ''}
    </Link>
  ) : href ? (
    <a
      className={`button ${className} ${
        isColorBtn ? 'button_type_color' : ''
      } ${isBigBtn ? 'button_size_big' : ''} ${
        isWidthRestricted ? 'button_width_restricted' : ''
      } ${isSmallBtn ? 'button_size_small' : ''} ${
        onCardBtn ? 'button_type_card' : ''
      }`}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      {text}
      {icon ? icon : ''}
    </a>
  ) : (
    <button
      className={`button ${className} ${
        isColorBtn ? 'button_type_color' : ''
      } ${isBigBtn ? 'button_size_big' : ''} ${
        isWidthRestricted ? 'button_width_restricted' : ''
      } ${isSmallBtn ? 'button_size_small' : ''} ${
        onCardBtn ? 'button_type_card' : ''
      }`}
      type="button"
      onClick={onClick ? onClick : undefined}
    >
      {text}
      {icon ? icon : ''}
    </button>
  );
}

export default Button;
