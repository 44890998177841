import useWindowSize from '../../../../assets/hooks/useWindowSize';
import './PersonCard.css';

function PersonCard({ data }) {
  const { width } = useWindowSize();
  const name = data.name.replace(/&#182;/g, width > 900 ? '<br />' : ' ');
  const job = data.job.replace(/&#182;/g, width > 900 ? '<br />' : ' ');
  const description = data.description.replace(/&#182;/g, width > 900 ? '<br />' : ' ');

  return (
    <div className="person-card">
      <img className="person-card__photo" src={data.photo} alt={data.name} />
      <div className="person-card__info">
        <p
          className="person-card__title"
          dangerouslySetInnerHTML={{ __html: name }}
        />
        <p
          className="person-card__subtitle"
          dangerouslySetInnerHTML={{ __html: job }}
        />
        <p
          className="person-card__text"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
    </div>
  );
}

export default PersonCard;
