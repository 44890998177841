import { useContext } from 'react';
import './Leader.css';
import { TranslationContext } from '../../../assets/contexts/translationContext';
import Button from '../../Button/Button';
import ball from '../../../assets/images/main/leader/ball.webp';
import target from '../../../assets/images/main/leader/target-icon.webp';
import money from '../../../assets/images/main/leader/money-icon.webp';
import heart from '../../../assets/images/main/leader/heart-icon.webp';
import man from '../../../assets/images/main/leader/man-icon.webp';
import { FormPopupContext } from '../../../assets/contexts/formPopupContext';

const icons = [target, money, heart, man];

function Leader() {
  const {
    main: {
      leader: { title, cards, join },
    },
  } = useContext(TranslationContext);
  const { setIsFormPopupOpen } = useContext(FormPopupContext);

  function openFormPopup() {
    setIsFormPopupOpen(true);
  }

  return (
    <div className="leader">
      <div className="leader__content">
        <div className="leader__title-block">
          <p
            className="leader__title"
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <Button
            text={join}
            onClick={openFormPopup}
            className="leader__btn"
            isColorBtn
            isWidthRestricted
          />
        </div>

        <ul className="leader__cards-list">
          {cards.map((card, i) => (
            <li className="leader__card" key={i}>
              <div className="leader__card-heading">
                <p
                  className="leader__card-title"
                  dangerouslySetInnerHTML={{ __html: card.title }}
                />
                <img
                  src={icons[i]}
                  alt={card.title}
                  className="leader__card-icon"
                />
              </div>
              <p
                className="leader__card-text"
                dangerouslySetInnerHTML={{ __html: card.text }}
              />
            </li>
          ))}
        </ul>

        <Button
          text={join}
          onClick={openFormPopup}
          className="leader__btn leader__btn_type_mobile"
          isColorBtn
          isWidthRestricted
        />

        <img src={ball} alt="" className="leader__ball" />
        <div className="leader__circle leader__circle_size_m" />
        <div className="leader__circle leader__circle_size_l" />
        <div className="leader__ellipse leader__ellipse_size_s" />
        <div className="leader__ellipse leader__ellipse_size_l" />
      </div>
    </div>
  );
}

export default Leader;
