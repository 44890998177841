import './Agenda.css';
import cards from '../../assets/images/agenda/agenda-cards.webp'

import { useContext } from 'react';
import { TranslationContext } from '../../assets/contexts/translationContext';
import { BUY_TICKET_LINK, SPEAKERS_MAP } from '../../assets/utils/constants';
import PageHeading from '../PageHeading/PageHeading';
import useWindowSize from '../../assets/hooks/useWindowSize';

function Agenda() {
  const { agenda } = useContext(TranslationContext);
  const { timetable } = agenda;
  const { dayFirst, daySecond } = timetable;
  const { width } = useWindowSize();


  const TABLE = Object.keys(timetable).map(dayKey => ({
    data: timetable[dayKey].map(event => ({
      time: event.time,
      place: event.place,
      text: event.text,
      subtext: event.subtext,
      speakers: event.speakers && event.speakers.length > 0 ? event.speakers.map(author => ({
        author,
        photo: SPEAKERS_MAP[author] || null
      })) : null,
    }))
  }));

  // const TABLE = [
  //   {
  //     data: [
  //       {
  //         time: '08:30 – 09:30',
  //         text: `${dayFirst.text1}`,
  //       },
  //       {
  //         time: '09:30 – 10:00',
  //         place: `${dayFirst.place21}`,
  //         place2: `${dayFirst.place22}`,
  //         text: `${dayFirst.text2}`,
  //       },
  //       {
  //         time: '10:00 – 10:20',
  //         place: `${dayFirst.place3}`,
  //         text: `${dayFirst.text3}`,
  //       },
  //       {
  //         time: '11:00 – 12:30',
  //         place: `${dayFirst.place4}`,
  //         text: `${dayFirst.text4}`,
  //         subtext: [
  //           `${dayFirst.subtext41}`,
  //           `${dayFirst.subtext42}`,
  //           `${dayFirst.subtext43}`
  //         ],
  //         speakers: [
  //           {
  //             author: `${dayFirst.speakers4.author1}`,
  //             photo: author,
  //           },
  //           {
  //             author: `${dayFirst.speakers4.author2}`,
  //             photo: author,
  //           }
  //         ]
  //       },
  //       {
  //         time: '12:30 – 12:45',
  //         place: `${dayFirst.place5}`,
  //         text: `${dayFirst.text5}`,
  //         speakers: [
  //           {
  //             author: `${dayFirst.speakers5.author1}`,
  //             photo: author,
  //           }
  //         ]
  //       },
  //       {
  //         time: '12:45 – 13:15',
  //         place: `${dayFirst.place6}`,
  //         text: `${dayFirst.text6}`,
  //         subtext: [
  //           `${dayFirst.subtext61}`
  //         ],
  //         speakers: [
  //           {
  //             author: `${dayFirst.speakers6.author1}`,
  //             photo: author,
  //           }
  //         ]
  //       },
  //       {
  //         time: '13:15 – 13:45',
  //         place: `${dayFirst.place7}`,
  //         text: `${dayFirst.text7}`,
  //         subtext: [
  //           `${dayFirst.subtext71}`,
  //         ],
  //         speakers: [
  //           {
  //             author: `${dayFirst.speakers7.author1}`,
  //             photo: author,
  //           },
  //           {
  //             author: `${dayFirst.speakers7.author2}`,
  //             photo: author,
  //           },
  //           {
  //             author: `${dayFirst.speakers7.author3}`,
  //             photo: author,
  //           },
  //         ]
  //       },
  //       {
  //         time: '13:45 – 14:15',
  //         place: `${dayFirst.place8}`,
  //         text: `${dayFirst.text8}`,
  //         subtext: [
  //           `${dayFirst.subtext81}`,
  //         ],
  //         speakers: [
  //           {
  //             author: `${dayFirst.speakers8.author1}`,
  //             photo: author,
  //           },
  //           {
  //             author: `${dayFirst.speakers8.author2}`,
  //             photo: author,
  //           },
  //         ]
  //       },
  //       {
  //         time: '14:15 – 15:15',
  //         text: `${dayFirst.text9}`,
  //       },
  //       {
  //         time: '15:15 – 16:00',
  //         place: `${dayFirst.text10}`,
  //         text: `${dayFirst.text10}`,
  //       },
  //       {
  //         time: '16:00 – 16:30',
  //         place: `${dayFirst.place11}`,
  //         text: `${dayFirst.text11}`,
  //         subtext: [
  //           `${dayFirst.subtext111}`,
  //         ],
  //         // speakers: [
  //         //   {
  //         //     author: 'Lasha Shikhashvili',
  //         //     photo: author,
  //         //   },
  //         //   {
  //         //     author: 'George Mamulaishvili',
  //         //     photo: author,
  //         //   },
  //         // ]
  //       },
  //       {
  //         time: '16:30 – 17:00',
  //         place: `${dayFirst.place12}`,
  //         text: `${dayFirst.text12}`,
  //         speakers: [
  //           {
  //             author: `${dayFirst.speakers12.author1}`,
  //             photo: author,
  //           },
  //           {
  //             author: `${dayFirst.speakers12.author2}`,
  //             photo: author,
  //           },
  //         ]
  //       },
  //       {
  //         time: '17:00 – 17:30',
  //         place: `${dayFirst.place13}`,
  //         text: `${dayFirst.text13}`,
  //         speakers: [
  //           {
  //             author: `${dayFirst.speakers13.author1}`,
  //             // photo: author,
  //           },
  //         ]
  //       },
  //       {
  //         time: '17:30 – 18:00',
  //         place: `${dayFirst.place14}`,
  //         text: `${dayFirst.place14}`,
  //         speakers: [
  //           {
  //             author: `${dayFirst.speakers14.author1}`,
  //             photo: author,
  //           },
  //           {
  //             author: `${dayFirst.speakers14.author2}`,
  //             photo: author,
  //           },
  //         ]
  //       },
  //       {
  //         time: '18:00 – 18:30',
  //         place: `${dayFirst.place15}`,
  //         text: `${dayFirst.text15}`,
  //       },
  //       {
  //         time: '18:30 – 18:40',
  //         place: `${dayFirst.place16}`,
  //         text: `${dayFirst.text16}`,
  //       },
  //       {
  //         time: '19:10',
  //         place: `${dayFirst.place17}`,
  //         text: `${dayFirst.text17}`,
  //       },
  //       {
  //         time: '19:00 – 21:00',
  //         place: `${dayFirst.place18}`,
  //         text: `${dayFirst.text18}`,
  //       },
  //       {
  //         time: '21:00 – 00:00',
  //         place: `${dayFirst.place19}`,
  //         text: `${dayFirst.text19}`,
  //       },
  //     ]
  //   },

  //   {
  //     data: [
  //       {
  //         time: '09:00 – 10:00',
  //         place: `${daySecond.place1}`,
  //         text: `${daySecond.text1}`,
  //       },
  //       {
  //         time: '10:00 – 11:00',
  //         place: `${daySecond.place2}`,
  //         text: `${daySecond.text2}`,
  //         speakers: [
  //           {
  //             author: `${daySecond.speakers2.author1}`,
  //             photo: author
  //           },
  //           {
  //             author: `${daySecond.speakers2.author2}`,
  //             photo: author
  //           },
  //         ]
  //       },
  //       {
  //         time: '11:00 – 12:00',
  //         place: `${daySecond.place3}`,
  //         text: `${daySecond.text3}`,
  //         subtext: [
  //           `${daySecond.subtext31}`,
  //           `${daySecond.subtext32}`,
  //         ],
  //         speakers: [
  //           {
  //             author: `${daySecond.speakers3.author1}`,
  //             photo: author,
  //           },
  //           {
  //             author: `${daySecond.speakers3.author2}`,
  //             photo: author,
  //           }
  //         ]
  //       },
  //       {
  //         time: '12:00 – 12:30',
  //         place: `${daySecond.place4}`,
  //         text: `${daySecond.text4}`,
  //         speakers: [
  //           {
  //             author: `${daySecond.speakers4.author1}`,
  //             photo: author,
  //           },
  //           {
  //             author: `${daySecond.speakers4.author2}`,
  //             photo: author,
  //           },
  //         ]
  //       },
  //       {
  //         time: '12:30',
  //         place: `${daySecond.place5}`,
  //         text: `${daySecond.text5}`,
  //       },
  //       {
  //         time: '13:30',
  //         text: `${daySecond.text6}`,
  //       },
  //       {
  //         time: '20:00 – 22:00',
  //         text: `${daySecond.text7}`,
  //         place: `${daySecond.place7}`,
  //       },
  //     ]
  //   }
  // ]

  return (
    <section className="agenda">
      <PageHeading title={width > 400 ? agenda.mainTitle : agenda.mainTitleMobile} btnText={agenda.btnTicket} link={BUY_TICKET_LINK}>
        <img alt='' src={cards} className='agenda__img-cards' />
      </PageHeading>

      <div className='agenda__content'>
        {TABLE.map((item, i) => {
          return (
            <div key={i} className='agenda__table-day__day-block'>
              <p className='agenda__table-day__title'>{agenda.dayText} {i + 1}</p>
              {item.data.map((item2, i) => {
                return (
                  <div key={`table-line-${i}`} className='agenda__table-day__line'>
                    <div className='agenda__table-day__time-place-box'>
                      <p className='agenda__table-day__time'>
                        {item2.time}
                      </p>
                      <p className='agenda__table-day__place'>
                        {item2.place ? item2.place : null}
                        <p>{item2.place2 && window.innerHeight < 900 ? '•' : <br></br>}</p>
                        {item2.place2 ? item2.place2 : null}
                      </p>
                    </div>
                    <div>
                      <p className='agenda__table-day__text'>
                        {item2.text ? item2.text : null}
                      </p>
                      {item2.subtext ?
                        <div className='agenda__table-day__subtext-box'>
                          {item2?.subtext?.map((item3, i) => {
                            return (
                              <div key={`table-text-${i}`} className='agenda__table-day__subtext'>
                                <div className='agenda__table-day__subtext__circle'></div>
                                <p>{item3}</p>
                              </div>
                            )
                          })
                          }
                        </div>
                        : null}
                    </div>
                    <div>
                      <p className='agenda__table-day__speakers__title'>
                        {item2.speakers ? `${agenda.speakersTitle}:` : null}
                      </p>
                      {item2.speakers ?
                        <div className='agenda__table-day__speakers__box'>
                          {item2?.speakers?.map((item4, i) => {
                            return (
                              <div key={`table-speakers-${i}`} className='agenda__table-day__speakers-line'>
                                {item4.photo ?
                                  <img alt='' className='agenda__table-day__speakers__img' src={item4.photo}></img>
                                  :
                                  <div className='agenda__table-day__speakers__img_placeholder'></div>
                                }
                                <div className='agenda__table-day__speakers__author'>{item4.author}</div>
                              </div>
                            )
                          })
                          }
                        </div>
                        : null}
                    </div>
                  </div>
                )
              })}
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default Agenda;
