import { useRef, useEffect } from 'react';
import './Popup.css';
import { CloseIcon, Logo } from '../../assets/icons/icons';

function Popup({ isOpen, onClose, children, className = '' }) {
  const overlay = useRef();

  useEffect(() => {
    function handleOverlayClose(evt) {
      if (overlay.current === evt.target) {
        onClose();
      }
    }

    document.addEventListener('mousedown', handleOverlayClose);
    return () => {
      document.removeEventListener('mousedown', handleOverlayClose);
    };
  });

  return (
    <div className={`popup ${isOpen ? 'popup_opened' : ''}`} ref={overlay}>
      <div className={`popup__container ${className}`}>
        <button className="popup__close-button" type="button" onClick={onClose}>
          <CloseIcon
            className="popup__close-icon"
            strokeClassName="popup__close-icon-stroke"
          />
        </button>

        <Logo
          className="popup__logo"
          textClassName="popup__logo-text"
          fillClassName="popup__logo-fill"
        />

        {children}
      </div>
    </div>
  );
}

export default Popup;
