import { motion } from 'framer-motion';
import { CheckIcon } from '../../assets/icons/icons';
import './Checkbox.css';

function Checkbox({ name, onChange, checked, label, error }) {
  return (
    <div className="checkbox">
      <div className="checkbox__container">
        <label className="checkbox__box" htmlFor={name}>
          <input
            className="checkbox__invisible-input"
            id={name}
            name={name}
            type="checkbox"
            value={checked}
            checked={checked}
            onChange={onChange}
          />
          <span
            className={`checkbox__pseudo-item ${
              checked ? 'checkbox__pseudo-item_checked' : ''
            }`}
          >
            {CheckIcon({
              mainClassName: 'checkbox__check-icon',
              fillClassName: 'checkbox__check-icon-fill',
            })}
          </span>
        </label>
        <span
          className="checkbox__label"
          dangerouslySetInnerHTML={{ __html: label }}
        />
      </div>

      {error && (
        <motion.div
          className="checkbox__error-box"
          initial={{ height: 0, marginTop: 0, opacity: 0 }}
          animate={{ height: 'auto', marginTop: 4, opacity: 1 }}
          exit={{ height: 0, marginTop: 0, opacity: 0 }}
          transition={{ duration: 0.15 }}
        >
          <span className="checkbox__error">{error}</span>
        </motion.div>
      )}
    </div>
  );
}

export default Checkbox;
