import Button from '../Button/Button';
import './PageHeading.css';
import wave from '../../assets/images/page-heading/wave.webp';
import waveMobile from '../../assets/images/page-heading/wave-mobile.webp';

function PageHeading({ title, btnText, link, onClick, children, titleClassName = '' }) {
  return (
    <div className="page-heading">
      <div
        className={`page-heading__content ${
          !btnText ? 'page-heading__content_type_no-btn' : ''
        }`}
      >
        <div className="page-heading__title-block">
          <h2
            className={`page-heading__title ${titleClassName}`}
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
          {btnText && (
            <Button
              className="page-heading__btn"
              text={btnText}
              link={link}
              onClick={onClick}
              isColorBtn
              isWidthRestricted
            />
          )}
        </div>

        {children}

        <img alt="" src={wave} className="page-heading__bg" />
        <img
          alt=""
          src={waveMobile}
          className="page-heading__bg page-heading__bg_type_mobile"
        />
        <div className="page-heading__gradient" />
      </div>
    </div>
  );
}

export default PageHeading;
