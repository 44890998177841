import { useContext } from 'react';
import './MainHeading.css';
import banner from '../../../assets/images/main/heading/banner.webp';
import small from '../../../assets/images/main/heading/small-ball.webp';
import big from '../../../assets/images/main/heading/big-ball.webp';
import { TranslationContext } from '../../../assets/contexts/translationContext';
import { ABOUT_LINK, CONTACTS_LINK } from '../../../assets/utils/constants';
import Button from '../../Button/Button';

function MainHeading() {
  const {
    main: {
      heading: { month, title, subtitle, links },
    },
  } = useContext(TranslationContext);

  return (
    <div className="main-heading">
      <div className="main-heading__content">
        <div className="main-heading__text-block">
          <div className="main-heading__date">
            <p className="main-heading__title main-heading__title_type_color">
              17-18
            </p>
            <p className="main-heading__date-text">{month}</p>
            <p className="main-heading__title main-heading__title_type_color main-heading__title_weight_semibold">
              2024
            </p>
          </div>

          <div className="main-heading__text">
            <h1 className="main-heading__title">{title}</h1>
            <p className="main-heading__subtitle">{subtitle}</p>
          </div>

          <div className="main-heading__buttons">
            <Button text={links.about} link={ABOUT_LINK} isBigBtn isColorBtn />
            <Button text={links.contact} link={CONTACTS_LINK} isBigBtn />
          </div>
        </div>

        <div className="main-heading__image-block">
          <img src={banner} alt="" className="main-heading__image" />
          <img src={small} alt="" className="main-heading__small-ball" />
          <img src={big} alt="" className="main-heading__big-ball" />
          <div className="main-heading__rectangle" />
          <div className="main-heading__circle" />
        </div>
      </div>
    </div>
  );
}

export default MainHeading;
