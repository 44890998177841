import { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import './InfoBlock.css';
import { SmallArrowIcon } from '../../../assets/icons/icons';
import useWindowSize from '../../../assets/hooks/useWindowSize';

function InfoBlock({ title, content }) {
  const { width } = useWindowSize();
  const [isOpen, setIsOpen] = useState(false);

  function handleClick() {
    setIsOpen(!isOpen);
  }

  return (
    <div className="info-block">
      <button className="info-block__btn" type="button" onClick={handleClick}>
        <p
          className="info-block__title"
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <motion.div
          className="info-block__arrow-wrapper"
          initial={{ rotate: 0 }}
          animate={{ rotate: isOpen ? 180 : 0 }}
        >
          <SmallArrowIcon
            mainClassName="info-block__arrow"
            strokeClassName="info-block__arrow-stroke"
          />
        </motion.div>
      </button>

      <AnimatePresence>
        {isOpen && (
          <motion.ul
            className="info-block__content"
            initial={{
              opacity: 0,
              height: 0,
              y: -20,
              paddingTop: 0,
              paddingBottom: 0,
            }}
            animate={{
              opacity: 1,
              height: 'auto',
              y: 0,
              paddingTop: width > 900 ? 40 : 20,
              paddingBottom: width > 900 ? 40 : 20,
            }}
            exit={{
              opacity: 0,
              height: 0,
              y: -20,
              paddingTop: 0,
              paddingBottom: 0,
            }}
            transition={{ duration: 0.2 }}
          >
            {content.map((item, i) => (
              <li className="info-block__content-item" key={i}>
                <p
                  className="info-block__content-text"
                  dangerouslySetInnerHTML={{ __html: item }}
                />
              </li>
            ))}
          </motion.ul>
        )}
      </AnimatePresence>
    </div>
  );
}

export default InfoBlock;
