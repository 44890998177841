import { useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { TranslationContext } from '../../../assets/contexts/translationContext';
import './MainJoin.css';
import Button from '../../Button/Button';
import { BUY_TICKET_LINK, PARTNER_TYPE } from '../../../assets/utils/constants';
import { FormPopupContext } from '../../../assets/contexts/formPopupContext';

function MainJoin() {
  const {
    main: {
      join: { title, text, numbers, buttons },
    },
  } = useContext(TranslationContext);
  const { setIsFormPopupOpen } = useContext(FormPopupContext);
  const [, setSearchParams] = useSearchParams();

  const numbersArr = [
    {
      number: '1000+',
      text: numbers.delegates,
    },
    {
      number: '50+',
      text: numbers.affiliates,
    },
    {
      number: '40+',
      text: numbers.speakers,
    },
    {
      number: '40+',
      text: numbers.exhibitors,
    },
  ];

  function openFormPopup() {
    setIsFormPopupOpen(true);
    setSearchParams({ type: PARTNER_TYPE.value });
  }

  return (
    <div className="main-join">
      <div className="main-join__content">
        <div className="main-join__text-block">
          <p className="main-join__title">{title}</p>
          <p className="main-join__text">{text}</p>
        </div>

        <div className="main-join__info-block">
          <ul className="main-join__numbers-list">
            {numbersArr.map((item, i) => (
              <li className="main-join__numbers-item" key={i}>
                <p className="main-join__numbers-number">{item.number}</p>
                <p className="main-join__numbers-text">{item.text}</p>
              </li>
            ))}
          </ul>

          <div className="main-join__buttons-block">
            <Button
              text={buttons.becomePartner}
              onClick={openFormPopup}
              isColorBtn
            />
            <Button text={buttons.buyTicket} link={BUY_TICKET_LINK} />
          </div>
        </div>

        <div className="main-join__circle" />
      </div>
    </div>
  );
}

export default MainJoin;
