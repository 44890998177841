import './Partners.css';
import { useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { TranslationContext } from '../../assets/contexts/translationContext';
import PartnersCard from './PartnersCard/PartnersCard';
import rixos from '../../assets/images/partners/rixos.svg';
import caba from '../../assets/images/partners/caba.png';
import jelly from '../../assets/images/partners/jelly.png';
import coins from '../../assets/images/partners/coins.webp';
import glitter from '../../assets/images/partners/glitter.webp';
import basket from '../../assets/images/partners/basketball.webp';
import football from '../../assets/images/partners/football.webp';
import PageHeading from '../PageHeading/PageHeading';
import { FormPopupContext } from '../../assets/contexts/formPopupContext';
import { PARTNER_TYPE } from '../../assets/utils/constants';

function Partners() {
  const { partners } = useContext(TranslationContext);
  const { partnerList } = partners;
  const { setIsFormPopupOpen } = useContext(FormPopupContext);
  const [, setSearchParams] = useSearchParams();

  const PARTNERS = [
    {
      title: `${partnerList.general.title}`,
      type: 'general',
      partners: [
        {
          img: rixos,
          title: `${partnerList.general.partners.title1}`,
          desc: `${partnerList.general.partners.desc1}`,
          link: 'rixos.com/hotel-resort/rixos-water-world-aktau',
        },
        {
          img: caba,
          title: `${partnerList.general.partners.title2}`,
          desc: `${partnerList.general.partners.desc2}`,
          link: 'cabahub.com',
        },
        // {
        //   img: jelly,
        //   title: `${partnerList.general.partners.title3}`,
        //   desc: `${partnerList.general.partners.desc3}`,
        //   link: 'www.rixoswaterworld.kz',
        // },
        // {
        //   img: rixos,
        //   title: `${partnerList.general.partners.title4}`,
        //   desc: `${partnerList.general.partners.desc4}`,
        //   link: 'www.rixoswaterworld.kz',
        // },
        // {
        //   img: rixos,
        //   title: `${partnerList.general.partners.title5}`,
        //   desc: `${partnerList.general.partners.desc5}`,
        //   link: 'www.rixoswaterworld.kz',
        // },
      ],
    },
    {
      title: `${partnerList.media.title}`,
      partners: [
        {
          img: rixos,
          title: `${partnerList.media.partners.title1}`,
          link: 'rixos.com/hotel-resort/rixos-water-world-aktau',
        },
        // {
        //   img: caba,
        //   title: `${partnerList.media.partners.title1}`,
        //   link: 'cabahub.com',
        // },
      ],
    },
  ];

  function openFormPopup() {
    setIsFormPopupOpen(true);
    setSearchParams({ type: PARTNER_TYPE.value });
  }

  return (
    <section className="partners">
      <PageHeading title={partners.mainTitle} btnText={partners.btnPartner} onClick={openFormPopup}>
        <div className="partners__img-container">
          <img alt="" className="partners__img" src={coins} />
          <img alt="" className="partners__img-bg" src={glitter} />
          <div className="partners__circle" />
        </div>
        <img src={football} alt="" className="partners__img-football" />
        <img src={basket} alt="" className="partners__img-basketball" />
      </PageHeading>

      <div className="partners__content">
        <div className="partners__all">
          {PARTNERS.map((item, i) => {
            return (
              <div key={i} className="partners__grid-partners-box ">
                <p className="partners__grid-partners__title">{item.title}</p>
                <div
                  className={`partners__grid-partners ${
                    item.type ? 'partners__grid-partners_general' : ''
                  }`}
                >
                  {item.partners.map((item2, i2) => {
                    return item.type ? (
                      <PartnersCard item={item2} i={i2} general={true} />
                    ) : (
                      <PartnersCard item={item2} i={i2} general={false} />
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default Partners;
