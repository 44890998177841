import { useContext } from 'react';
import { TranslationContext } from '../../../assets/contexts/translationContext';
import './Sponsorship.css';
import { SPONSORS_LINK } from '../../../assets/utils/constants';
import coins from '../../../assets/images/main/sponsorship/coins.webp';
import glitter from '../../../assets/images/main/sponsorship/glitter.webp';
import Button from '../../Button/Button';

function Sponsorship() {
  const {
    main: {
      sponsorship: { title, text, btnText },
    },
  } = useContext(TranslationContext);

  return (
    <div className="sponsorship">
      <div className="sponsorship__content">
        <div className="sponsorship__title-block">
          <p
            className="sponsorship__title"
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <p
            className="sponsorship__text sponsorship__text_type_mobile"
            dangerouslySetInnerHTML={{ __html: text }}
          />
          <Button
            text={btnText}
            link={SPONSORS_LINK}
            className="sponsorship__btn"
            isColorBtn
            isWidthRestricted
          />
        </div>

        <p
          className="sponsorship__text"
          dangerouslySetInnerHTML={{ __html: text }}
        />

        <div className="sponsorship__img-block">
          <img src={coins} alt="" className="sponsorship__img" />
          <img src={glitter} alt="" className="sponsorship__img-bg" />
        </div>

        <div className="sponsorship__circle" />
      </div>
    </div>
  );
}

export default Sponsorship;
