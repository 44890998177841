import './Conference.css';
import ConfHeading from './ConfHeading/ConfHeading';
import ConfInfo from './ConfInfo/ConfInfo';
import Speakers from './Speakers/Speakers';
import Opportunities from './Opportunities/Opportunities';

function Conference() {
  return (
    <section className="conference">
      <ConfHeading />
      <ConfInfo />
      <Speakers />
      <Opportunities />
    </section>
  );
}

export default Conference;
