import { useContext } from 'react';
import './MainPartners.css';
import { TranslationContext } from '../../../assets/contexts/translationContext';
import {
  GgaLogo,
  iGamingLogo,
  OffsideLogo,
  PlayLogo,
  RixosLogo,
  RoundArrowIcon,
} from '../../../assets/icons/icons';
import { Link } from 'react-router-dom';
import { PARTNERS_LINK } from '../../../assets/utils/constants';

function MainPartners() {
  const {
    main: {
      partners: { title, general, media, generalMedia, partner, btnText },
    },
  } = useContext(TranslationContext);

  const partnersList = [
    {
      type: general,
      icon: RixosLogo,
    },
    {
      type: partner,
      icon: GgaLogo,
    },
    {
      type: generalMedia,
      icon: PlayLogo,
    },
    {
      type: media,
      icon: OffsideLogo,
    },
    {
      type: partner,
      icon: iGamingLogo,
    },
  ];

  return (
    <div className="main-partners">
      <div className="main-partners__content">
        <p className="main-partners__title">{title}</p>
        <ul className="main-partners__list">
          {partnersList.map((partner, i) => (
            <li className="main-partners__card" key={i}>
              <p className="main-partners__card-title">{partner.type}</p>
              <partner.icon
                mainClassName="main-partners__card-icon"
                fillClassName="main-partners__card-icon-fill"
                strokeClassName="main-partners__card-icon-stroke"
              />
            </li>
          ))}
          <Link
            to={PARTNERS_LINK}
            className="main-partners__btn main-partners__btn_type_mobile"
          >
            <RoundArrowIcon
              mainClassName="main-partners__btn-icon"
              fillClassName="main-partners__btn-icon-fill"
            />
            {btnText}
          </Link>
        </ul>
        <Link to={PARTNERS_LINK} className="main-partners__btn">
          {btnText}
          <RoundArrowIcon
            mainClassName="main-partners__btn-icon"
            fillClassName="main-partners__btn-icon-fill"
          />
        </Link>
      </div>
    </div>
  );
}

export default MainPartners;
