import { useState, useContext, useRef, useEffect } from 'react';
import { Link, NavLink, useSearchParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import './Header.css';
import { MAIN_LINK, NAV_LINKS, LANGUAGES } from '../../assets/utils/constants';
import { Logo, BurgerIcon, SmallArrowIcon } from '../../assets/icons/icons';
import { TranslationContext } from '../../assets/contexts/translationContext';
import MobileMenu from './MobileMenu/MobileMenu';
import { FormPopupContext } from '../../assets/contexts/formPopupContext';

function Header({ language }) {
  const { header } = useContext(TranslationContext);
  const { setIsFormPopupOpen } = useContext(FormPopupContext);
  const [, setSearchParams] = useSearchParams();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLangDropdownOpen, setIsLangDropdownOpen] = useState(false);
  const langDropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        langDropdownRef.current &&
        !langDropdownRef.current.contains(event.target)
      ) {
        setIsLangDropdownOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleMenuClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLangClick = () => {
    setIsLangDropdownOpen(!isLangDropdownOpen);
  };

  function selectLanguage(lang) {
    setSearchParams((params) => {
      params.set('lang', lang);
      return params;
    });
    handleLangClick();
  }

  function openFormPopup() {
    setIsFormPopupOpen(true);
  }

  return (
    <header className="header">
      <div className="header__container">
        <Link className="header__logo-link" to={MAIN_LINK}>
          <Logo
            mainClassName="header__logo"
            textClassName="header__logo-text"
            fillClassName="header__logo-fill"
          />
        </Link>

        <button
          className="header__menu-button"
          type="button"
          onClick={handleMenuClick}
        >
          <BurgerIcon
            mainClassName="header__menu-button-icon"
            strokeClassName="header__menu-button-stroke"
          />
        </button>

        <nav className="header__nav">
          <ul className="header__nav-list">
            {NAV_LINKS.map((link) => (
              <li className="header__nav-item" key={link.link}>
                <NavLink
                  className={({ isActive }) =>
                    `header__nav-link ${
                      isActive ? 'header__nav-link_active' : ''
                    } ${link.disabled ? 'header__nav-link_disabled' : ''}`
                  }
                  to={link.link}
                >
                  {link.title[language]}
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>

        <div className="header__controls">
          <div className="header__lang-switcher-container">
            <button
              className="header__lang-switcher"
              type="button"
              onClick={handleLangClick}
            >
              <p className="header__lang-switcher-text">
                {LANGUAGES[language].title}
              </p>
              <motion.div
                className="header__lang-switcher-arrow-container"
                initial={{ rotate: 0 }}
                animate={{ rotate: isLangDropdownOpen ? 180 : 0 }}
              >
                <SmallArrowIcon
                  mainClassName="header__lang-switcher-arrow"
                  strokeClassName="header__lang-switcher-arrow-stroke"
                />
              </motion.div>
            </button>

            {isLangDropdownOpen && (
              <>
                <div className="header__lang-dropdown-overlay" />
                <motion.div
                  className="header__lang-dropdown"
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  ref={langDropdownRef}
                >
                  {Object.values(LANGUAGES).map((lang) => (
                    <button
                      className={`header__lang-dropdown-button ${
                        lang.value === language
                          ? 'header__lang-dropdown-button_selected'
                          : ''
                      }`}
                      key={lang.value}
                      type="button"
                      onClick={() => selectLanguage(lang.value)}
                    >
                      {lang.title}
                    </button>
                  ))}
                </motion.div>
              </>
            )}
          </div>

          <button
            className="header__join-button"
            type="button"
            onClick={openFormPopup}
          >
            {header.join}
          </button>
        </div>
      </div>

      <MobileMenu
        isOpen={isMenuOpen}
        onClose={handleMenuClick}
        language={language}
        onLanguageSelect={selectLanguage}
      />
    </header>
  );
}

export default Header;
