export const RU_TRANSLATION = {
    header: {
        join: 'Участвовать',
        location: {
            place: 'Rixos Water World Aktau',
            city: 'Актау, Казахстан',
        },
    },

    footer: {
        text: 'Саммит по гемблингу и беттингу Центральной Азии',
        date: '17-18 октября 2024',
    },

    main: {
        heading: {
            month: 'Октября',
            title: 'Саммит по гемблингу и беттингу Центральной Азии',
            subtitle: 'Первое в истории мероприятие мирового класса в регионе',
            links: {
                about: 'О Саммите',
                contact: 'Контакты',
            },
        },

        join: {
            title: 'Станьте частью',
            text: 'Саммита гемблинга и беттинга Центральной Азии вместе с лидерами индустрии!',
            numbers: {
                delegates: 'Делегатов',
                affiliates: 'Аффилиатов',
                speakers: 'Спикеров',
                exhibitors: 'Участников',
            },
            buttons: {
                becomePartner: 'Стать партнером',
                buyTicket: 'Купить билет',
            },
        },

        place: {
            title: 'Rixos Water World Aktau',
            text: 'Lorem ipsum dolor sit amet consectetur. Nulla maecenas vulputate montes pellentesque convallis ligula amet elementum. Sapien facilisis augue euismod dictum. Elementum eget est lobortis aenean.',
            buttons: {
                book: 'Забронировать',
                partners: 'Все партнеры',
            },
        },

        sponsorship: {
            title: 'Станьте нашим спонсором&nbsp;—',
            text: 'Повышайте узнаваемость вашего бренда с&nbsp;нашим эксклюзивным партнерством. Получите максимальную видимость через эксклюзивное выставочное пространство, премиальную рекламу и&nbsp;возможности для выступлений. Ваш логотип будет размещен на&nbsp;всех площадках мероприятия, обеспечивая максимальную узнаваемость и&nbsp;влияние.',
            btnText: 'Подробнее',
        },

        leader: {
            title: 'Казахстан - региональный лидер игорной индустрии',
            join: 'Участвовать',
            cards: [
                {
                    title: 'Специальные<br />игровые зоны',
                    text: 'Введение специальных зон для игорного бизнеса в&nbsp;Алматы и&nbsp;Акмолинской области позволяет сконцентрировать бизнес и&nbsp;контролировать его. Это создает благоприятную среду для инвесторов и&nbsp;операторов, учитывая опыт и&nbsp;положительные стороны игорных зон на&nbsp;примере развитых стран.',
                },
                {
                    title: 'Экономический<br />рост',
                    text: 'Игорный бизнес может стать мощным инструментом развития региональной экономики. Гэмблинг способствует развитию смежных отраслей, таких как реклама, информационные технологии и&nbsp;платежные системы. Ставки положительно влияют на&nbsp;достижение гендерного баланса в&nbsp;спорте и&nbsp;его развитие. Налоговые поступления от&nbsp;Игорного бизнеса увеличивают бюджет страны.',
                },
                {
                    title: 'Ответственная<br />игра',
                    text: 'Казахстан может задавать тон в&nbsp;развитии бизнеса, придерживаясь принципов ответственной игры.',
                },
                {
                    title: 'Привлечение<br />туристов',
                    text: 'Развитие инфраструктуры, отелей и&nbsp;развлекательных комплексов привлечет иностранных игроков. Казахстан может стать популярным направлением для игрового туризма.',
                },
            ],
        },

        about: {
            title: 'Конференция',
            text: 'Ответственная игра и&nbsp;открытие регионов для игорного бизнеса в&nbsp;Казахстане.<br />Перспективы развития рынка азартных игр и&nbsp;ставок в&nbsp;Узбекистане после легализации в&nbsp;2025 году',
            btnText: 'О конференции',
        },

        show: {
            title: 'Вас ждет яркое шоу!',
            text: 'В&nbsp;турнире примут участие лучшие спортсмены из&nbsp;Центральной Азии и&nbsp;других частей мира, включая Бразилию.',
        },

        partners: {
            title: 'Партнеры',
            general: 'Генеральный партнер',
            media: 'Медиа-партнер',
            generalMedia: 'Генеральный медиа-партнер',
            partner: 'Партнер',
            btnText: 'Все партнеры',
        },
    },

    conference: {
        heading: {
            title: 'О конференции',
        },

        info: {
            title: 'Ответственная игра и&nbsp;открытие новых специализированных регионов для игорного бизнеса в&nbsp;Казахстане',
            text: 'Перспективы развития рынка азартных игр и&nbsp;ставок в&nbsp;Узбекистане после легализации в&nbsp;2025 году',
            goals: {
                title: 'Наша цель:',
                text: [
                    'Изучить будущее игорного бизнеса в&nbsp;Казахстане, Узбекистане и&nbsp;Центральной Азии в&nbsp;целом, обсудить текущие вопросы и&nbsp;продвигать ответственную игру, подчеркнуть преимущества и&nbsp;выгоды открытия новых специализированных регионов для индустрии и&nbsp;государства, способствовать развитию эффективной государственной политики и&nbsp;рыночных структур.',
                    'Для обсуждения законодательства, регулирования и&nbsp;мер по&nbsp;обеспечению ответственной игры были приглашены соответствующие министерства и&nbsp;регуляторы Казахстана и&nbsp;Узбекистана. Операторы азартных игр поделятся своим опытом и&nbsp;передовыми практиками.',
                ],
            },
        },

        speakers: {
            our: 'НАШИ',
            title: 'Спикеры',
            agenda: 'Программа',
            card: {
                title: 'Стать спикером',
                text: 'Lorem ipsum dolor sit amet consectetur. Nulla maecenas vulputate montes pellentesque convallis ligula amet elementum. Sapien facilisis augue euismod dictum.',
                btnText: 'Заполнить форму',
            },
            speakers: [
                {
                    name: 'Сиддхарт Гупта',
                    job: 'Генеральный директор SilverAce Media,&#182;Эстония',
                    description:
                        'Технологические инновации: SEO и&nbsp;e-⁠commerce. Аффилиаты. Развитие спорта через беттинг-компании. Реклама азартных игр.',
                },
                {
                    name: 'Илья Мачавариани',
                    job: 'Генеральный директор и старший партнер&#182;4H Agency',
                    description: 'Законодательство и&nbsp;регулирование.',
                },
                {
                    name: 'Норберт Рубичек',
                    job: 'Директор Sport Integrity&#182;Group',
                    description:
                        'Спорт и&nbsp;технологии. Развитие спорта через беттинг-компании.',
                },
                {
                    name: 'Михаил Деметрашвили',
                    job: 'Генеральный директор,&#182;Leader Pay',
                    description: 'Криптовалюты и&nbsp;цифровые активы',
                },
                {
                    name: 'Давит Киквидзе',
                    job: 'Глава Финтех Ассоциации Грузии, эксперт Всемирного банка',
                    description: 'Криптовалюты и&nbsp;цифровые активы',
                },
                {
                    name: 'Георгий Мамулаишвили',
                    job: 'Глава Ассоциации игорного&#182;бизнеса Грузии',
                    description: 'Законодательство и&nbsp;регулирование.<br />Ответственная игра.',
                },
                {
                    name: 'Ниниа Чхеидзе',
                    job: 'Менеджер по операционной деятельности',
                    description:
                        'Использование потребительских инсайтов и&nbsp;инновационных технологий для целевого маркетинга и&nbsp;повышения вовлеченности клиентов.',
                },
                {
                    name: 'Маханалин Болат',
                    job: 'Президент Фонда "Поддержка национального спорта"',
                    description: 'Спорт и&nbsp;технологии.',
                },
                {
                    name: 'Джон Бамиделе',
                    job: 'Посол ICE&#182;в Африке',
                    description:
                        'Анализ индустрии азартных игр в&nbsp;Африке, ее&nbsp;потенциал, вызовы и&nbsp;сходства с&nbsp;азиатским игорным рынком.',
                },
                {
                    name: 'Ярослав Козлюк',
                    job: 'Менеджер проекта&#182;Casino Eclipse',
                    description: 'Развитие игорных зон.',
                },
                {
                    name: 'Иван Курочкин',
                    job: 'Партнер и руководитель департамента Восточной Европы 4H&nbsp;Agency',
                    description: 'Ответственная игра.',
                },
                {
                    name: 'Лаша Шихашвили',
                    job: 'Модератор конференции.&#182;Бизнес-консультант. Эксперт в игорном бизнесе',
                    description:
                        'Ответственная игра как основа ценностей для современного оператора казино. IT-инструменты для ответственной игры.',
                },
                {
                    name: 'Гига Вашакидзе',
                    job: 'Руководитель по развитию бизнеса в Leadership Scanner – внедрение инноваций',
                    description:
                        'Организационное развитие, технологии и&nbsp;инновации. SEO и&nbsp;электронная коммерция.',
                },
            ],
        },

        opportunities: {
            title: 'Эксклюзивные возможности',
            btnText: 'Посетить',
            cards: [
                {
                    title: 'Важность ценностей<br />игроков',
                    text: 'Ценности игроков влияют на&nbsp;их&nbsp;решения в&nbsp;азартных играх, такие как развлечения, социальное взаимодействие и&nbsp;стремление к&nbsp;победе. Операторам азартных игр необходимо учитывать эти ценности, чтобы создавать персонализированный и&nbsp;приятный опыт. Ответственная игра и&nbsp;ценности взаимосвязаны, и&nbsp;поддержание баланса между развлечением и&nbsp;здравым смыслом позволяет игрокам наслаждаться игрой без риска.',
                },
                {
                    title: 'Открытие новых регионов для&nbsp;игорного&nbsp;бизнеса',
                    text: 'Анализ потенциала развития азартных игр в специальных экономических зонах Казахстана. Преимущества для инвесторов и операторов при выходе на новые рынки. Возможности развития туризма и привлечения иностранных игроков.',
                },
                {
                    title: 'Ответственная<br />игра',
                    text: 'Обсуждение принципов ответственной игры и&nbsp;роли регуляторов и&nbsp;операторов в&nbsp;их&nbsp;обеспечении. Представление инструментов и&nbsp;методов защиты игроков от&nbsp;проблемного поведения.',
                },
                {
                    title: 'Законодательные обновления и&nbsp;политики',
                    text: 'На&nbsp;основе лучших международных практик, обсуждение существующего законодательства, его негативных аспектов и&nbsp;предложений по&nbsp;его улучшению, что будет способствовать улучшению условий работы операторов и&nbsp;привлечению дополнительных инвестиций для развития отрасли.',
                },
                {
                    title: 'Преимущества для развития туризма',
                    text: 'Азартные игры могут стать мощным инструментом для развития игрового туризма, привлекая иностранных игроков. Налоги и&nbsp;лицензии увеличат бюджет страны и&nbsp;стимулируют развитие отелей, ресторанов и&nbsp;развлекательных комплексов.',
                },
                {
                    title: 'Круглые столы<br />и сессии вопросов и ответов',
                    text: 'Участники смогут подробно обсудить конкретные вопросы, поднятые на&nbsp;панельных дискуссиях, и&nbsp;задать вопросы экспертам.',
                },
                {
                    title: 'Роль беттинга<br />в развитии спорта',
                    text: 'Сегодня беттинг играет важную роль в&nbsp;развитии спорта в&nbsp;стране. Спонсорство спортивных мероприятий и&nbsp;атлетов дает им&nbsp;возможность соревноваться на&nbsp;международной арене и&nbsp;представлять свою страну. На&nbsp;панельной дискуссии будут обсуждаться плюсы и&nbsp;минусы участия азартных игр в&nbsp;спорте, честная игра команд и&nbsp;методы повышения ответственности в&nbsp;спорте.',
                },
                {
                    title: 'Соответствие нормативам<br />и борьба с отмыванием денег',
                    text: 'Борьба с&nbsp;отмыванием денег является важным компонентом прозрачности азартных игр. По&nbsp;мере того, как она становится более привлекательной, она повышает имидж и&nbsp;защищает потребителей.',
                },
            ],
        },
    },

    agenda: {
        mainTitle: 'Программа конференции',
        mainTitleMobile: 'Программа конференции',
        btnTicket: 'Купить билет',
        dayText: 'День',
        speakersTitle: 'Спикеры',
        timetable: {
            dayFirst: [
                {
                    time: '08:30 – 09:30',
                    place: '',
                    text: `Прибытие делегатов. 
                Трансфер на автобусе из города в отель`,
                    subtext: [],
                    speakers: []
                },
                {
                    time: '09:30 – 10:00',
                    place: 'Лобби отеля, Конференц-зал',
                    text: `Регистрация делегатов. 
                Приветственный кофе и напитки.`,
                    subtext: [],
                    speakers: []
                },
                {
                    time: '10:00 – 10:20',
                    place: 'Конференц-зал',
                    text: `Открытие конференции. 
                Приветственное слово.`,
                    subtext: [],
                    speakers: []
                },
                {
                    time: '11:00 – 12:30',
                    place: 'Конференц-зал',
                    text: 'Регулирование и соответствие требованиям.',
                    subtext: [
                        'Регулирование в Казахстане, Узбекистане, Кыргызстане и Грузии.',
                        'Сравнение законодательных рамок в странах СНГ, постсоветском регионе и на Балканах.',
                        'Обновления в законодательстве и налогообложении, регулирование и развитие игорной индустрии, вопросы и улучшения, налогообложение и административные системы.'
                    ],
                    speakers: ['Георгий Мамулаишвили', 'Илья Мачавариани', 'Представители Министерства туризма и спорта', 'Министерство национальной экономики', 'Министерство финансов (уточняется)']
                },
                {
                    time: '12:30 – 12:45',
                    place: 'Конференц-зал',
                    text: 'Анализ игорной индустрии Африки, ее потенциала, вызовов и сходств с азиатским игорным рынком',
                    subtext: [],
                    speakers: ['Джон (Адеолу) Бамиделе']
                },
                {
                    time: '12:45 – 13:15',
                    place: 'Конференц-зал',
                    text: `Важность игорного бизнеса для экономического и туристического развития в Центральной Азии
          
                Панельная дискуссия об экономическом и туристическом развитии и преимуществах создания новых зон.`,
                    subtext: [`Участники узнают, как эффективно применять инструменты ответственной игры и как балансировать государственное регулирование и реализацию в бизнесе для роста индустрии при обеспечении качественного государственного регулирования`],
                    speakers: ['Ярослав Козлюк', 'Представитель Ассоциации туризма Казахстана (уточняется)']
                },
                {
                    time: '13:15 – 13:45',
                    place: 'Конференц-зал',
                    text: `Ответственная игра
          
                Панельная дискуссия о внедрении инструментов ответственной игры, их преимуществах для общества и бизнес-сектора, а также о балансе государственного регулирования и бизнес-реализации.`,
                    subtext: [`Участники узнают, как эффективно применять инструменты ответственной игры и как балансировать государственное регулирование и реализацию в бизнесе для роста индустрии при обеспечении качественного государственного регулирования`],
                    speakers: ['Георгий Мамулаишвили', 'Лаша Шихашвили', 'Иван Курочкин']
                },
                {
                    time: '13:45 – 14:15',
                    place: 'Конференц-зал',
                    text: `Кодекс поведения для игроков или как бороться с игровой зависимостью
          
                Обсуждение проблем игровой зависимости, решений и способов снижения числа зависимых через повышение осведомленности и защиту уязвимых групп.`,
                    subtext: [`Участники обсудят проблемы игровой зависимости, способы решения и снижения числа зависимых через информирование и повышение осведомленности, а также защиту несовершеннолетних и уязвимых групп`],
                    speakers: ['Лаша Шихашвили', 'Георгий Мамулаишвили', 'Психолог (уточняется)', 'Представитель реабилитационного центра (уточняется)']
                },
                {
                    time: '14:15 – 15:15',
                    place: 'Конференц-зал',
                    text: `Перерыв`,
                    subtext: [],
                    speakers: []
                },
                {
                    time: '15:15 – 16:00',
                    place: 'Конференц-зал',
                    text: `Взвешивание бойцов ММА`,
                    subtext: [],
                    speakers: []
                },
                {
                    time: '16:00 – 16:30',
                    place: 'Конференц-зал',
                    text: `AML и KYC — борьба с теневыми денежными потоками
                        
                Обсуждение глобальных методов обнаружения незаконных финансовых потоков и важности систем KYC в игорной индустрии.`,
                    subtext: [`Участники обсудят, какие методы и практики существуют во всем мире для обнаружения незаконных финансовых транзакций и важность роли систем KYC (Знай своего клиента) в игорной индустрии. Преимущества для правительств и компаний от внедрения таких систем и контроля`],
                    speakers: ['Представители Агентства финансового мониторинга (уточняется)', 'Академия финансового мониторинга (уточняется)']
                },
                {
                    time: '16:30 – 17:00',
                    place: 'Конференц-зал',
                    text: `Организационное развитие, технологии и инновации. SEO и E-Commerce
                        
                Панельная дискуссия`,
                    subtext: [],
                    speakers: ['Сиддхарт Гупта', 'Гига Вашакидзе']
                },
                {
                    time: '17:00 – 17:30',
                    place: 'Конференц-зал',
                    text: `SEO и партнерские программы
                        
                Панельная дискуссия`,
                    subtext: [],
                    speakers: ['Сиддхарт Гупта']
                },
                {
                    time: '17:30 – 18:00',
                    place: 'Конференц-зал',
                    text: `Криптовалюты, цифровые активы и электронные платежные системы
                        
                Панельная дискуссия`,
                    subtext: [],
                    speakers: ['Давит Киквидзе', 'Михаил Деметрашвили', 'Представитель Международного финансового центра "Астана" (уточняется)']
                },
                {
                    time: '18:00 – 18:30',
                    place: 'Конференц-зал',
                    text: `WEB3 Gaming: игра начинается
                        
                Обсуждение технологий Web3 и их влияния на игорную индустрию`,
                    subtext: [],
                    speakers: ['Никита Величко', 'Егор Розинский']
                },
                {
                    time: '18:30 – 18:40',
                    place: 'Конференц-зал',
                    text: `Закрытие первого дня`,
                    subtext: [],
                    speakers: []
                },
                {
                    time: '19:10',
                    place: 'Лобби отеля',
                    text: `Трансфер на автобусе в город`,
                    subtext: [],
                    speakers: []
                },
                {
                    time: '19:00 – 21:00',
                    place: 'Ресторан "Русалка"',
                    text: `Закрытый ужин`,
                    subtext: [],
                    speakers: []
                },
                {
                    time: '21:00 – 00:00',
                    place: 'Лобби отеля',
                    text: `Нетворкинг (вечеринка)`,
                    subtext: [],
                    speakers: []
                },
            ],
            daySecond: [
                {
                    time: '09:00 – 10:00',
                    place: 'Конференц-зал',
                    text: `Прибытие делегатов. 
                Кофе и напитки. 
                Трансфер на автобусе из города в отель`,
                    subtext: [],
                    speakers: []
                },
                {
                    time: '10:00 – 11:00',
                    place: 'Конференц-зал',
                    text: `Спорт и технологии
                        
                Панельная дискуссия`,
                    subtext: [],
                    speakers: ['Маханалин Болат', 'Норберт Рубичек']
                },
                {
                    time: '11:00 – 12:00',
                    place: 'Конференц-зал',
                    text: `Развитие спорта с помощью беттинг-компаний и спортивных медиа
                        
                Панель разделена на две части:`,
                    subtext: [`Презентация директора Sport Integrity Group`, `Панельная дискуссия`],
                    speakers: ['Маханалин Болат', 'Норберт Рубичек']
                },
                {
                    time: '12:00 – 12:30',
                    place: 'Конференц-зал',
                    text: `Рекламная деятельность в игорном бизнесе
                        
                Панельная дискуссия о рекламных технологиях, инновациях, маркетинговых стратегиях и поведенческой науке для удержания игроков`,
                    subtext: [],
                    speakers: ['Сиддхарт Гупта', 'Ниния Чхеидзе']
                },
                {
                    time: '12:30',
                    place: 'Конференц-зал',
                    text: `Закрытие конференции и выставки`,
                    subtext: [],
                    speakers: []
                },
                {
                    time: '13:30',
                    place: 'Лобби отеля',
                    text: `Трансфер на автобусе в город`,
                    subtext: [],
                    speakers: []
                },
                {
                    time: '20:00 – 22:00',
                    place: 'Конференц-зал',
                    text: `Турнир ММА`,
                    subtext: [],
                    speakers: []
                },
                // ... Добавьте остальные события для второго дня
            ]
        },
    },

    exhibition: {
        mainTitle: 'Выставка iGaming &&nbsp;Betting',
        btnMember: 'Стать участником',
        titleExhibition: 'Выставка',
        textExhibition:
            'Ответственная игра и открытие новых специализированных регионов для игорного бизнеса в Казахстане. Перспективы развития рынка азартных игр и ставок в Узбекистане после легализации в 2025 году',
        placeTextRixos: 'Rixos Water World Aktau',
        placeTextKz: 'Актау, Казахстан',
        legend: {
            text1: 'Стенды спонсоров',
            text2: 'Свободные стенды',
            text3: 'Занятые стенды',
        },
    },

    sponsors: {
        title: 'Спонсоры конференции',
        btnText: 'Стать спонсором',
        quantity: 'Количество',
        benefits: 'Преимущества спонсорства',
        obligations: 'Обязательства спонсора',
        types: [
            {
                typeBtn: 'Платиновый',
                title: 'Платиновый спонсор',
                benefits: [
                    'Выставочная площадь для Платинового спонсора до 40 квадратных метров.',
                    'Информационный и рекламный модуль Платинового спонсора в официальной программе Саммита (4-я страница обложки + 1 цветная страница).',
                    'Приветственное обращение генерального директора компании Платинового спонсора в официальной программе Саммита (1 страница).',
                    'Участие и приветственная речь представителя компании Платинового спонсора на официальной церемонии открытия мероприятия (если она проводится).',
                    'Предоставление конференц-зала (до 1 часа) для презентации компании Платинового спонсора в ходе конференции.',
                    'Размещение 3 представителей Платинового спонсора в отеле RIXOS Water World Aktau с 16 по 19 октября 2024 года на период проведения Саммита.',
                    'Пригласительные билеты для 3 представителей Платинового спонсора на Гала-ужин в первый день Саммита.',
                    'Распространение рекламно-информационных материалов Платинового спонсора (буклеты, брошюры и другая печатная продукция) на Информационной стойке в ходе Саммита.',
                    'Баннер Платинового спонсора (5м x 2,5м) на территории выставки в ходе Саммита.',
                    'Баннер Платинового спонсора (2м x 1м) в конференц-зале в ходе конференции.',
                    'Ежедневная трансляция рекламного видеоролика Платинового спонсора (до 1 минуты) на экранах в павильоне не менее 10 раз в час в ходе Саммита.',
                    'Логотип Платинового спонсора на пригласительных билетах мероприятия.',
                    'Логотип Платинового спонсора на официальных бейджах мероприятия.',
                    'Логотип Платинового спонсора в рекламных материалах мероприятия, размещенных в деловых изданиях, специализированных журналах, каталогах, на сайтах, баннерах и т.д.',
                    'Логотип Платинового спонсора на первой странице официальной программы Саммита.',
                    'Информация о Платиновом спонсоре в информационных письмах о мероприятии, распространяемых посредством целевой рассылки по почте, электронной почте и т.д.',
                    'Информация о Платиновом спонсоре на официальном сайте Саммита с гиперссылкой на сайт компании.',
                    'Информация о новинках и мероприятиях Платинового спонсора в социальных сетях мероприятия (при условии своевременного предоставления материала).',
                    'Публикация эксклюзивного интервью с представителями Платинового спонсора до мероприятия на соответствующих новостных порталах, таких как www.CasinoBeats.com.',
                    'Регулярные публикации в группе CAGBS на LinkedIn с упоминанием Платинового спонсора.',
                    'Логотип Платинового спонсора на рекламе CAGB Summit на световом коробе размером 4м x 2м в зале прилета Международного аэропорта Актау или на световом коробе размером 3,2м x 3м в общем зале в зоне прилета.',
                ],
                obligations: [
                    'Размещение информации о Саммите (баннер, анонс) на официальном сайте Платинового спонсора с гиперссылкой на сайт Саммита не позднее чем за 1,5 месяца до мероприятия.',
                    'Размещение информации о Саммите в социальных сетях Платинового спонсора не позднее чем за 1,5 месяца до мероприятия.',
                    'Распространение информации о Саммите партнерам и клиентам Платинового спонсора посредством целевой email-рассылки (если такая рассылка проводится) с кратким отчетом о рассылке.',
                    'Максимально широкое распространение информации о статусе компании Платинового спонсора как Платинового спонсора мероприятия представителями компании Платинового спонсора.',
                ],
            },
            {
                typeBtn: 'Золотой',
                title: 'Золотой спонсор',
                benefits: [
                    'Предоставление выставочной площади для Золотого спонсора до 32 квадратных метров.',
                    'Информационный и рекламный модуль Золотого спонсора в официальной программе Саммита (3-я страница обложки + 1 цветная страница).',
                    'Приветственное обращение генерального директора компании Золотого спонсора в официальной программе Саммита (1 страница).',
                    'Участие и приветственная речь представителя компании Золотого спонсора на официальной церемонии открытия мероприятия (если она проводится).',
                    'Предоставление конференц-зала (до 1 часа) для презентации компании Золотого спонсора в ходе конференции.',
                    'Размещение 2 представителей Золотого спонсора в отеле RIXOS Water World Aktau с 16 по 19 октября 2024 года на период проведения Саммита.',
                    'Пригласительные билеты для 2 представителей Золотого спонсора на Гала-ужин в первый день Саммита.',
                    'Распространение рекламно-информационных материалов Золотого спонсора (буклеты, брошюры и другая печатная продукция) на Информационной стойке в ходе Саммита.',
                    'Баннер Золотого спонсора (4м x 2,5м) на территории выставки в ходе Саммита.',
                    'Баннер Золотого спонсора (2м x 1м) в конференц-зале в ходе конференции.',
                    'Ежедневная трансляция рекламного видеоролика Золотого спонсора (до 1 минуты) на экранах в павильоне не менее 7 раз в час в ходе Саммита.',
                    'Логотип Золотого спонсора на пригласительных билетах мероприятия.',
                    'Логотип Золотого спонсора на официальных бейджах мероприятия.',
                    'Логотип Золотого спонсора в рекламных материалах мероприятия, размещенных в деловых изданиях, специализированных журналах, каталогах, на сайтах, баннерах и т.д.',
                    'Логотип Золотого спонсора на первой странице официальной программы Саммита.',
                    'Информация о Золотом спонсоре в информационных письмах о мероприятии, распространяемых посредством целевой рассылки по почте, электронной почте и т.д.',
                    'Информация о Золотом спонсоре на официальном сайте Саммита с гиперссылкой на сайт компании.',
                    'Информация о новинках и мероприятиях Золотого спонсора в социальных сетях мероприятия (при условии своевременного предоставления материала).',
                    'Публикация эксклюзивного интервью с представителями Золотого спонсора до мероприятия на соответствующих новостных порталах, таких как www.CasinoBeats.com.',
                    'Периодические публикации в группе CAGBS на LinkedIn с упоминанием Золотого спонсора.',
                    'Размещение логотипа Золотого спонсора на рекламе CAGB Summit на световом коробе размером 4м x 2м в зале прилета Международного аэропорта Актау или на световом коробе размером 3,2м x 3м в общем зале в зоне прилета.',
                ],
                obligations: [
                    'Размещение информации о Саммите (баннер, анонс) на официальном сайте Золотого спонсора с гиперссылкой на сайт Саммита не позднее чем за 1,5 месяца до мероприятия.',
                    'Размещение информации о Саммите в социальных сетях Золотого спонсора не позднее чем за 1,5 месяца до мероприятия.',
                    'Распространение информации о Саммите партнерам и клиентам Золотого спонсора посредством целевой email-рассылки (если такая рассылка проводится) с кратким отчетом о рассылке.',
                    'Максимально широкое распространение информации о статусе компании Золотого спонсора представителями компании Золотого спонсора.',
                ],
            },
            {
                typeBtn: 'Серебряный',
                title: 'Серебряный спонсор',
                benefits: [
                    'Предоставление выставочной площади для Серебряного спонсора до 24 квадратных метров.',
                    'Информационный и рекламный модуль Серебряного спонсора в официальной программе Саммита (2 цветные страницы).',
                    'Приветственное обращение генерального директора компании Серебряного спонсора в официальной программе Саммита (1 страница).',
                    'Участие и приветственная речь представителя компании Серебряного спонсора на официальной церемонии открытия мероприятия (если она проводится).',
                    'Предоставление конференц-зала (до 1 часа) для презентации компании Серебряного спонсора в ходе конференции.',
                    'Размещение 1 представителя Серебряного спонсора в отеле RIXOS Water World Aktau с 16 по 19 октября 2024 года на период проведения Саммита.',
                    'Пригласительный билет для 1 представителя Серебряного спонсора на Гала-ужин в первый день Саммита.',
                    'Распространение рекламно-информационных материалов Серебряного спонсора (буклеты, брошюры и другая печатная продукция) на Информационной стойке в ходе Саммита.',
                    'Баннер Серебряного спонсора (3м x 2,5м) на территории выставки в ходе Саммита.',
                    'Баннер Серебряного спонсора (2м x 1м) в конференц-зале в ходе конференции.',
                    'Ежедневная трансляция рекламного видеоролика Серебряного спонсора (до 1 минуты) на экранах в павильоне не менее 5 раз в час в ходе Саммита.',
                    'Логотип Серебряного спонсора на пригласительных билетах мероприятия.',
                    'Логотип Серебряного спонсора на официальных бейджах мероприятия.',
                    'Логотип Серебряного спонсора в рекламных материалах мероприятия, размещенных в деловых изданиях, специализированных журналах, каталогах, на сайтах, баннерах и т.д.',
                    'Логотип Серебряного спонсора на первой странице официальной программы Саммита.',
                    'Информация о Серебряном спонсоре в информационных письмах о мероприятии, распространяемых посредством целевой рассылки по почте, электронной почте и т.д.',
                    'Информация о Серебряном спонсоре на официальном сайте Саммита с гиперссылкой на сайт компании.',
                    'Информация о новинках и мероприятиях Серебряного спонсора в социальных сетях мероприятия (при условии своевременного предоставления материала).',
                    'Периодические публикации в группе CAGBS на LinkedIn с упоминанием Серебряного спонсора.',
                    'Публикация эксклюзивного интервью с представителями Серебряного спонсора до мероприятия на соответствующих новостных порталах, таких как www.CasinoBeats.com.',
                    'Размещение логотипа Серебряного спонсора на рекламе CAGB Summit на световом коробе размером 4м x 2м в зале прилета Международного аэропорта Актау или на световом коробе размером 3,2м x 3м в общем зале в зоне прилета.',
                ],
                obligations: [
                    'Размещение информации о Саммите (баннер, анонс) на официальном сайте Серебряного спонсора с гиперссылкой на сайт Саммита не позднее чем за 1,5 месяца до мероприятия.',
                    'Размещение информации о Саммите в социальных сетях Серебряного спонсора не позднее чем за 1,5 месяца до мероприятия.',
                    'Распространение информации о Саммите партнерам и клиентам Серебряного спонсора посредством целевой email-рассылки (если такая рассылка проводится) с кратким отчетом о рассылке.',
                    'Максимально широкое распространение информации о статусе компании Серебряного спонсора представителями компании Серебряного спонсора.',
                ],
            },
            {
                typeBtn: 'Конференция',
                title: 'Спонсор конференции',
                benefits: [
                    'Брендирование арки при входе в конференц-зону.',
                    'Регулярные публикации в группе CAGBS на LinkedIn с упоминанием спонсора конференции.',
                    'Предоставление конференц-зала (до 30 минут) для презентации компании спонсора конференции в ходе конференции.',
                    'Информационный и рекламный модуль спонсора конференции в официальной программе Саммита (1 цветная страница).',
                    'Распространение рекламно-информационных материалов спонсора конференции (листовки, брошюры и другая печатная продукция) на регистрационной стойке в ходе Саммита.',
                    '2 баннера спонсора конференции (2м x 1м) в конференц-зале в ходе конференции.',
                    'Ежедневная трансляция рекламного видеоролика спонсора конференции (до 30 секунд) на экранах в павильоне не менее 5 раз в час в ходе Саммита.',
                    'Логотип спонсора конференции на странице поддержки в официальной программе Саммита.',
                    'Информация о спонсоре конференции в информационных письмах о мероприятии, распространяемых посредством целевой рассылки по почте, электронной почте.',
                    'Информация о спонсоре конференции на официальном сайте Саммита с гиперссылкой на сайт компании.',
                    'Информация о новинках и мероприятиях спонсора конференции в социальных сетях мероприятия (при условии своевременного предоставления материала).',
                ],
                obligations: [
                    'Размещение информации о Саммите (баннер, анонс) на официальном сайте спонсора конференции с гиперссылкой на сайт Саммита не позднее чем за 1,5 месяца до мероприятия.',
                    'Размещение информации о Саммите в социальных сетях спонсора конференции не позднее чем за 1,5 месяца до мероприятия.',
                    'Распространение информации о Саммите партнерам и клиентам спонсора конференции посредством целевой email-рассылки (если такая рассылка проводится) с кратким отчетом о рассылке.',
                    'Максимально широкое распространение информации о статусе компании как спонсора конференции представителями компании спонсора конференции.',
                ],
            },
            {
                typeBtn: 'Кофе-брейк',
                title: 'Спонсор кофе-брейка и лаунж-зоны',
                benefits: [
                    'Брендирование зон кофе-брейка и лаунж согласно макету спонсора.',
                    'Предоставление спонсором кофе-брейка и лаунж-зоны брендированной одежды для обслуживающего персонала, брендированной посуды (чашки, стаканы, тарелки и т.д.).',
                    'Распространение рекламно-информационных материалов спонсора кофе-брейка и лаунж-зоны (листовки, брошюры и другая печатная продукция) на информационной стойке в ходе Саммита.',
                    'Баннер спонсора кофе-брейка и лаунж-зоны (2м x 1м) в главном фойе в зоне регистрации в ходе конференции.',
                    'Ежедневная трансляция рекламного видеоролика спонсора кофе-брейка и лаунж-зоны (до 30 секунд) на экранах в павильоне не менее 5 раз в час в ходе Саммита.',
                    'Логотип спонсора кофе-брейка и лаунж-зоны на странице поддержки в официальной программе Саммита.',
                    'Информация о спонсоре кофе-брейка и лаунж-зоны в информационных письмах о мероприятии, распространяемых посредством целевой рассылки по почте, электронной почте.',
                    'Информация о спонсоре кофе-брейка и лаунж-зоны на официальном сайте Саммита с гиперссылкой на сайт компании.',
                    'Информация о новинках и мероприятиях спонсора кофе-брейка и лаунж-зоны в социальных сетях мероприятия (при условии своевременного предоставления материала).',
                    'Регулярные публикации в группе CAGBS на LinkedIn с упоминанием спонсора кофе-брейка и лаунж-зоны.',
                ],
                obligations: [
                    'Предоставление спонсором кофе-брейка и лаунж-зоны брендированной посуды и униформы для персонала не позднее чем за 10 дней до мероприятия (количество согласовывается дополнительно).',
                    'Размещение информации о Саммите (баннер, анонс) на официальном сайте спонсора кофе-брейка и лаунж-зоны с гиперссылкой на сайт Саммита не позднее чем за 1,5 месяца до мероприятия.',
                    'Размещение информации о Саммите в социальных сетях спонсора кофе-брейка и лаунж-зоны не позднее чем за 1,5 месяца до мероприятия.',
                    'Распространение информации о Саммите партнерам и клиентам спонсора кофе-брейка и лаунж-зоны посредством целевой email-рассылки (если такая рассылка проводится) с кратким отчетом о рассылке.',
                    'Максимальное распространение информации представителями компании спонсора кофе-брейка и лаунж-зоны.',
                ],
            },
            {
                typeBtn: 'Регистрация',
                title: 'Спонсор регистрации',
                benefits: [
                    'Брендирование зоны регистрации согласно макету спонсора регистрации.',
                    'Предоставление спонсором регистрации брендированной одежды для персонала регистрации.',
                    'Распространение рекламно-информационных материалов спонсора регистрации (брошюры, листовки и другая печатная продукция) на стойке регистрации в ходе Саммита.',
                    '2 баннера спонсора регистрации (2м x 1м) в главном фойе в зоне регистрации в ходе конференции.',
                    'Ежедневная трансляция рекламного видеоролика спонсора регистрации (до 30 секунд) на экранах в павильоне не менее 5 раз в час в ходе Саммита.',
                    'Логотип спонсора регистрации на странице поддержки в официальной программе Саммита.',
                    'Информация о спонсоре регистрации в информационных письмах о мероприятии, распространяемых посредством целевой рассылки по почте, электронной почте.',
                    'Информация о спонсоре регистрации на официальном сайте Саммита с гиперссылкой на сайт компании.',
                    'Информация о новинках и мероприятиях спонсора регистрации в социальных сетях мероприятия (при условии своевременного предоставления материала).',
                    'Периодические публикации в группе CAGBS на LinkedIn с упоминанием спонсора регистрации.',
                ],
                obligations: [
                    'Размещение информации о Саммите (баннер, анонс) на официальном сайте спонсора регистрации с гиперссылкой на сайт Саммита не позднее чем за 1,5 месяца до мероприятия.',
                    'Размещение информации о Саммите в социальных сетях спонсора регистрации не позднее чем за 1,5 месяца до мероприятия.',
                    'Распространение информации о Саммите партнерам и клиентам спонсора регистрации посредством целевой email-рассылки (если такая рассылка проводится) с кратким отчетом о рассылке.',
                    'Максимально широкое распространение представителями компании спонсора регистрации информации о статусе компании как спонсора регистрации мероприятия.',
                ],
            },
            {
                typeBtn: 'Бейдж',
                title: 'Спонсор бейджа',
                benefits: [
                    'Логотип спонсора бейджа на официальных бейджах мероприятия.',
                    'Логотип спонсора бейджа на лентах для официальных бейджей мероприятия (ленты предоставляются спонсором).',
                    'Распространение рекламных материалов спонсора бейджа (листовки, брошюры и другая печатная продукция) на Информационной стойке в ходе Саммита.',
                    'Баннер спонсора бейджа (2м x 1м) в фойе во время мероприятия.',
                    'Ежедневная трансляция рекламного видеоролика спонсора бейджа (до 30 секунд) на экранах в павильоне не менее 5 раз в час в ходе Саммита.',
                    'Логотип спонсора бейджа на странице поддержки в официальной программе Саммита.',
                    'Информация о спонсоре бейджа в информационных письмах о мероприятии, распространяемых посредством почтовой и электронной рассылки.',
                    'Информация о спонсоре бейджа на официальном сайте Саммита с гиперссылкой на сайт компании.',
                    'Информация о новинках и мероприятиях спонсора бейджа в социальных сетях мероприятия (при условии своевременного предоставления материала).',
                    'Регулярные публикации в группе CAGBS на LinkedIn с упоминанием спонсора бейджа.',
                ],
                obligations: [
                    'Предоставление спонсором бейджа брендированных лент для официальных бейджей мероприятия не позднее чем за 15 дней до мероприятия (количество лент согласовывается дополнительно).',
                    'Размещение информации о Саммите (баннер, анонс) на официальном сайте спонсора бейджа с гиперссылкой на сайт Саммита не позднее чем за 1,5 месяца до мероприятия.',
                    'Размещение информации о Саммите в социальных сетях спонсора бейджа не позднее чем за 1,5 месяца до мероприятия.',
                    'Распространение информации о Саммите партнерам и клиентам спонсора бейджа посредством целевой email-рассылки (если такая рассылка проводится) с кратким отчетом о рассылке.',
                    'Максимально широкое распространение представителями компании спонсора бейджа информации о статусе компании как спонсора бейджа мероприятия.',
                ],
            },
            {
                typeBtn: 'Сувенир',
                title: 'Сувенирный спонсор',
                benefits: [
                    'Распространение сувенирной продукции (сумки, ручки, блокноты, сувениры и т.д.), утвержденной Организатором, участникам и посетителям CAGB Summit во время Саммита.',
                    'Распространение рекламно-информационных материалов сувенирного спонсора (буклеты, брошюры и другая печатная продукция) на Информационной стойке в ходе Саммита.',
                    'Баннер сувенирного спонсора (2м x 1м) в фойе во время мероприятия.',
                    'Ежедневная трансляция рекламного видеоролика сувенирного спонсора (до 30 секунд) на экранах в павильоне не менее 5 раз в час в ходе Саммита.',
                    'Логотип сувенирного спонсора на странице поддержки в официальной программе Саммита.',
                    'Информация о сувенирном спонсоре в информационных письмах о мероприятии, распространяемых посредством почтовой и электронной рассылки.',
                    'Информация о сувенирном спонсоре на официальном сайте Саммита с гиперссылкой на сайт компании.',
                    'Информация о новинках и мероприятиях сувенирного спонсора в социальных сетях мероприятия (при условии своевременного предоставления материала).',
                    'Регулярные публикации в группе CAGBS на LinkedIn с упоминанием сувенирного спонсора.',
                ],
                obligations: [
                    'Предоставление сувенирной продукции спонсором сувениров для участников и посетителей CAGB Summit не позднее чем за 10 дней до мероприятия (количество согласовывается дополнительно).',
                    'Размещение информации о Саммите (баннер, анонс) на официальном сайте сувенирного спонсора с гиперссылкой на сайт Саммита не позднее чем за 1,5 месяца до мероприятия.',
                    'Размещение информации о Саммите в социальных сетях сувенирного спонсора не позднее чем за 1,5 месяца до мероприятия.',
                    'Распространение информации о Саммите партнерам и клиентам сувенирного спонсора посредством целевой email-рассылки (если такая рассылка проводится) с кратким отчетом о рассылке.',
                    'Максимально широкое распространение представителями компании сувенирного спонсора информации о статусе компании как сувенирного спонсора мероприятия.',
                ],
            },
        ],
    },

    partners: {
        mainTitle: `Партнеры</br>конференции`,
        btnPartner: 'Стать партнером',
        partnerList: {
            general: {
                title: 'Генеральные партнеры',
                partners: {
                    title1: 'Rixos Water World Aktau',
                    desc1: `Lorem ipsum dolor sit amet tempus
                consectetur. Arcu ut cursus at pellentesque sit vehicula tempus nisl augue. Egestas et in elit id vulputate viverra.`,

                    title2: 'CABA HUB',
                    desc2: `Lorem ipsum dolor sit amet tempus
                consectetur. Arcu ut cursus at pellentesque sit vehicula tempus nisl augue. Egestas et in elit id vulputate viverra.`,

                    title3: 'Rixos Water World Aktau',
                    desc3: `Lorem ipsum dolor sit amet tempus
                consectetur. Arcu ut cursus at pellentesque sit vehicula tempus nisl augue. Egestas et in elit id vulputate viverra.`,

                    title4: 'Rixos Water World Aktau',
                    desc4: `Lorem ipsum dolor sit amet tempus
                consectetur. Arcu ut cursus at pellentesque sit vehicula tempus nisl augue. Egestas et in elit id vulputate viverra.`,

                    title5: 'Rixos Water World Aktau',
                    desc5: `Lorem ipsum dolor sit amet tempus
                consectetur. Arcu ut cursus at pellentesque sit vehicula tempus nisl augue. Egestas et in elit id vulputate viverra.`,
                },
            },
            media: {
                title: 'Медиа-партнеры',
                partners: {
                    title1: 'Rixos Water World Aktau',
                    title2: 'CABA HUB',
                },
            },
        },
    },

    contacts: {
        title: 'Контакты',
        text: 'Пожалуйста, заполните форму ниже<br />и мы свяжемся с вами в ближайшее время',
        location: {
            address: 'Закария Палиашвили, 17',
            city: 'Тбилиси, Грузия',
        },
    },

    form: {
        labels: {
            name: 'Имя',
            surname: 'Фамилия',
            email: 'E-mail',
            message: 'Сообщение',
            agreement: 'Я даю свое согласие на обработку&nbsp;персональных данных',
            submit: 'Отправить',
        },
    },
};