import { useState, useContext, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import validator from 'validator';
import { TranslationContext } from '../../assets/contexts/translationContext';
import Checkbox from '../Checkbox/Checkbox';
import Input from '../Input/Input';
import Popup from '../Popup/Popup';
import './FormPopup.css';
import { PARTNER_TYPE, SELECT_VALUES } from '../../assets/utils/constants';
import { FormPopupContext } from '../../assets/contexts/formPopupContext';

const initialValues = {
  type: {},
  name: '',
  surname: '',
  email: '',
  message: '',
  agreement: false,
};

const initialValuesValidity = {
  name: {},
  surname: {},
  email: {},
  message: {},
  agreement: {},
};

function FormPopup({ language }) {
  const {
    form: {
      labels: { agreement, submit, name, surname, email, message },
    },
  } = useContext(TranslationContext);
  const { isFormPopupOpen: isOpen, setIsFormPopupOpen: setOpen } =
    useContext(FormPopupContext);

  const [searchParams, setSearchParams] = useSearchParams();
  const type = searchParams.get('type');

  const [values, setValues] = useState(initialValues);
  const [valuesValidity, setValuesValidity] = useState(initialValuesValidity);
  const isFormValid = Object.values(valuesValidity).every(
    (value) => value.validState
  );

  useEffect(() => {
    const typeValue = type
      ? SELECT_VALUES.find((item) => item.value === type)
      : PARTNER_TYPE;
    setValues((prevValue) => ({
      ...prevValue,
      type: typeValue,
    }));
  }, [isOpen, type]);

  function handleClosePopup() {
    setOpen(false);
    setTimeout(() => {
      setSearchParams((params) => {
        params.delete('type');
        return params;
      });
      setValues(initialValues);
      setValuesValidity(initialValuesValidity);
    }, 300);
  }

  function handleChange(e) {
    const { name, value: targetValue, checked, type } = e.target;
    const value = type === 'checkbox' ? checked : targetValue;

    switch (name) {
      case 'email':
        if (!value) {
          setValuesValidity((prevValue) => ({
            ...prevValue,
            [name]: {
              errorMessage: '',
              validState: false,
            },
          }));
        }
        if (value.length >= 2) {
          if (validator.isEmail(value)) {
            setValuesValidity((prevValue) => ({
              ...prevValue,
              [name]: {
                errorMessage: '',
                validState: true,
              },
            }));
          } else {
            setValuesValidity((prevValue) => ({
              ...prevValue,
              [name]: {
                errorMessage: !e.target.validity.valid
                  ? e.target.validationMessage
                  : 'Invalid email',
                validState: false,
              },
            }));
          }
        }
        setValues((prevValue) => ({
          ...prevValue,
          [name]: value,
        }));
        break;

      case 'type':
        setValues((prevValue) => ({
          ...prevValue,
          [name]: value,
        }));
        break;

      default:
        setValues((prevValue) => ({
          ...prevValue,
          [name]: value,
        }));
        setValuesValidity((prevValue) => ({
          ...prevValue,
          [name]: {
            errorMessage: '',
            validState: Boolean(value),
          },
        }));
        break;
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
  }

  return (
    <Popup isOpen={isOpen} onClose={handleClosePopup} className="form-popup">
      <form className="form-popup__form" onSubmit={handleSubmit}>
        <Input
          name="type"
          value={values.type}
          selectList={SELECT_VALUES}
          onChange={handleChange}
          language={language}
          isSelect
        />
        <Input
          name="name"
          label={name}
          value={values.name}
          onChange={handleChange}
          error={valuesValidity.name.errorMessage}
        />
        <Input
          name="surname"
          label={surname}
          value={values.surname}
          onChange={handleChange}
          error={valuesValidity.surname.errorMessage}
        />
        <Input
          name="email"
          label={email}
          type="email"
          value={values.email}
          onChange={handleChange}
          error={valuesValidity.email.errorMessage}
        />
        <div className="form-popup__input form-popup__input_type_textarea">
          <Input
            name="message"
            label={message}
            value={values.message}
            onChange={handleChange}
            error={valuesValidity.message.errorMessage}
            isTextArea
          />
        </div>
        <Checkbox
          name="agreement"
          checked={values.agreement}
          label={agreement}
          error={valuesValidity.agreement.errorMessage}
          onChange={handleChange}
        />
        <button
          className={`form-popup__submit-button ${
            !isFormValid ? 'form-popup__submit-button_disabled' : ''
          }`}
          type="submit"
        >
          {submit}
        </button>
      </form>
    </Popup>
  );
}

export default FormPopup;
