import { useContext } from 'react';
import { TranslationContext } from '../../../assets/contexts/translationContext';
import './ConfInfo.css';

function ConfInfo() {
  const {
    conference: {
      info: { title, text, goals },
    },
  } = useContext(TranslationContext);

  return (
    <div className="conf-info">
      <div className="conf-info__content">
        <div className="conf-info__block">
          <p
            className="conf-info__title"
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <p
            className="conf-info__text"
            dangerouslySetInnerHTML={{ __html: text }}
          />
        </div>

        <div className="conf-info__block">
          <p className="conf-info__block-title">{goals.title}</p>
          <ul className="conf-info__list">
            {goals.text.map((item, i) => (
              <li key={i} className="conf-info__list-item">
                <p
                  className="conf-info__list-text"
                  dangerouslySetInnerHTML={{ __html: item }}
                />
              </li>
            ))}
          </ul>
        </div>

        <div className="conf-info__circle conf-info__circle_size_small" />
        <div className="conf-info__circle conf-info__circle_size_big" />
      </div>
    </div>
  );
}

export default ConfInfo;
