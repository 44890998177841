import { useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Mousewheel } from 'swiper/modules';
import './Opportunities.css';
import { TranslationContext } from '../../../assets/contexts/translationContext';
import Button from '../../Button/Button';
import 'swiper/css';
import 'swiper/css/pagination';
import { FormPopupContext } from '../../../assets/contexts/formPopupContext';
import { ATTENDER_TYPE } from '../../../assets/utils/constants';

function Opportunities() {
  const {
    conference: {
      opportunities: { title, cards, btnText },
    },
  } = useContext(TranslationContext);
  const { setIsFormPopupOpen } = useContext(FormPopupContext);
  const [, setSearchParams] = useSearchParams();

  function openFormPopup() {
    setIsFormPopupOpen(true);
    setSearchParams({ type: ATTENDER_TYPE.value });
  }

  return (
    <div className="opportunities">
      <div className="opportunities__content">
        <div className="opportunities__heading">
          <p className="opportunities__title">{title}</p>
          <Button
            text={btnText}
            className="opportunities__btn"
            isColorBtn
            isWidthRestricted
            onClick={openFormPopup}
          />
        </div>

        <ul className="opportunities__cards">
          {cards.map((card, i) => (
            <li className="opportunities__card" key={i}>
              <p
                className="opportunities__card-title"
                dangerouslySetInnerHTML={{ __html: card.title }}
              />
              <p
                className="opportunities__card-text"
                dangerouslySetInnerHTML={{ __html: card.text }}
              />
            </li>
          ))}
        </ul>

        <div className="opportunities__swiper-container">
          <Swiper
            className="opportunities__swiper"
            modules={[Pagination, Mousewheel]}
            spaceBetween={10}
            slidesPerView={1.1}
            pagination={{ clickable: true }}
            mousewheel={{ forceToAxis: true }}
            preventInteractionOnTransition={true}
            grabCursor={true}
          >
            {cards.map((card, i) => (
              <SwiperSlide key={i}>
                <li className="opportunities__card">
                  <p
                    className="opportunities__card-title"
                    dangerouslySetInnerHTML={{ __html: card.title }}
                  />
                  <p
                    className="opportunities__card-text"
                    dangerouslySetInnerHTML={{ __html: card.text }}
                  />
                </li>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        <Button
          text={btnText}
          onClick={openFormPopup}
          className="opportunities__btn opportunities__btn_type_mobile"
          isColorBtn
          isWidthRestricted
        />
      </div>
    </div>
  );
}

export default Opportunities;
