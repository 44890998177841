import About from './About/About';
import Leader from './Leader/Leader';
import './Main.css';
import MainHeading from './MainHeading/MainHeading';
import MainJoin from './MainJoin/MainJoin';
import MainPartners from './MainPartners/MainPartners';
import MainPlace from './MainPlace/MainPlace';
import Show from './Show/Show';
import Sponsorship from './Sponsorship/Sponsorship';

function Main({ language }) {
  return (
    <main className="main">
      <MainHeading />
      <MainJoin />
      <MainPlace />
      <Sponsorship />
      <Leader />
      <About {...{ language }} />
      <Show />
      <MainPartners />
    </main>
  );
}

export default Main;
