import { useContext } from 'react';
import './MainPlace.css';
import photo from '../../../assets/images/main/place/hotel.webp';
import { RixosLogo } from '../../../assets/icons/icons';
import { TranslationContext } from '../../../assets/contexts/translationContext';
import { PARTNERS_LINK, RIXOS_LINK } from '../../../assets/utils/constants';
import Button from '../../Button/Button';

function MainPlace() {
  const {
    main: {
      place: { title, text, buttons },
    },
  } = useContext(TranslationContext);

  return (
    <div className="main-place">
      <img className="main-place__image" src={photo} alt="" />
      <div className="main-place__content">
        <div className="main-place__block">
          <RixosLogo
            mainClassName="main-place__logo"
            fillClassName="main-place__logo-fill"
          />
          <p className="main-place__title">{title}</p>
          <p className="main-place__text">{text}</p>
          <div className="main-place__buttons">
            <Button
              text={buttons.book}
              href={RIXOS_LINK}
              isColorBtn
              onCardBtn
            />
            <Button text={buttons.partners} link={PARTNERS_LINK} onCardBtn />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainPlace;
