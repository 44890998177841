import { useContext } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { CloseIcon, Logo, PinIcon } from '../../../assets/icons/icons';
import './MobileMenu.css';
import {
  MAIN_LINK,
  NAV_LINKS,
  LANGUAGES,
} from '../../../assets/utils/constants';
import { TranslationContext } from '../../../assets/contexts/translationContext';
import { FormPopupContext } from '../../../assets/contexts/formPopupContext';

function MobileMenu({ isOpen, onClose, language, onLanguageSelect }) {
  const { header, footer } = useContext(TranslationContext);
  const { setIsFormPopupOpen } = useContext(FormPopupContext);

  function openFormPopup() {
    setIsFormPopupOpen(true);
    onClose();
  }

  return (
    <div
      className={`mobile-menu ${isOpen ? 'mobile-menu_opened' : ''}`}
      onClick={onClose}
    >
      <div
        className={`mobile-menu__overlay ${
          isOpen ? 'mobile-menu__overlay_opened' : ''
        }`}
      />
      <div
        className={`mobile-menu__container ${
          isOpen ? 'mobile-menu__container_opened' : ''
        }`}
        onClick={(e) => e.stopPropagation()}
      >
        <button
          className="mobile-menu__close-button"
          type="button"
          onClick={onClose}
        >
          <CloseIcon
            mainClassName="mobile-menu__close-icon"
            strokeClassName="mobile-menu__close-icon-stroke"
          />
        </button>

        <Link
          to={MAIN_LINK}
          className="mobile-menu__logo-link"
          onClick={onClose}
        >
          <Logo
            mainClassName="mobile-menu__logo"
            textClassName="mobile-menu__logo-text"
            fillClassName="mobile-menu__logo-fill"
          />
        </Link>

        <div className="mobile-menu__content">
          <div className="mobile-menu__content-block">
            <nav className="mobile-menu__nav">
              <ul className="mobile-menu__nav-list">
                {NAV_LINKS.map((link) => (
                  <li className="mobile-menu__nav-item" key={link.link}>
                    <NavLink
                      className={({ isActive }) =>
                        `mobile-menu__nav-link ${
                          isActive ? 'mobile-menu__nav-link_active' : ''
                        } ${
                          link.disabled ? 'mobile-menu__nav-link_disabled' : ''
                        }`
                      }
                      to={link.link}
                      onClick={onClose}
                    >
                      {link.title[language]}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </nav>

            <div className="mobile-menu__block">
              <div className="mobile-menu__lang-switcher-container">
                {Object.values(LANGUAGES).map((lang) => (
                  <button
                    className={`mobile-menu__lang-switcher ${
                      lang.value === language
                        ? 'mobile-menu__lang-switcher_selected'
                        : ''
                    }`}
                    key={lang.value}
                    type="button"
                    onClick={() => onLanguageSelect(lang.value)}
                  >
                    {lang.title}
                  </button>
                ))}
              </div>
            </div>

            <div className="mobile-menu__block">
              <div className="mobile-menu__location">
                <PinIcon
                  mainClassName="mobile-menu__location-icon"
                  strokeClassName="mobile-menu__location-icon-stroke"
                />
                <div className="mobile-menu__location-text-block">
                  <p className="mobile-menu__location-text">
                    {header.location.place}
                  </p>
                  <p className="mobile-menu__location-text mobile-menu__location-text_type_subtitle">
                    {header.location.city}
                  </p>
                </div>
              </div>
            </div>

            <button
              className="mobile-menu__join-button"
              type="button"
              onClick={openFormPopup}
            >
              {header.join}
            </button>
          </div>

          <p className="mobile-menu__footer-text">
            {footer.text}
            <br />
            {footer.date}
          </p>
        </div>
      </div>
    </div>
  );
}

export default MobileMenu;
