import { useContext } from 'react';
import './About.css';
import { TranslationContext } from '../../../assets/contexts/translationContext';
import Button from '../../Button/Button';
import { EN_LANGUAGE, EXHIBITION_LINK } from '../../../assets/utils/constants';
import { PinIcon } from '../../../assets/icons/icons';
import scheme_EN from '../../../assets/images/main/about/scheme_EN.webp';
import scheme_RU from '../../../assets/images/main/about/scheme_RU.webp';

function About({ language }) {
  const {
    header: {
      location: { place, city },
    },
    main: {
      about: { title, text, btnText },
    },
  } = useContext(TranslationContext);
  const scheme = language === EN_LANGUAGE ? scheme_EN : scheme_RU;

  return (
    <div className="about">
      <div className="about__container">
        <div className="about__content">
          <div className="about__text-block">
            <div className="about__title-box">
              <p className="about__title">{title}</p>
              <p
                className="about__text"
                dangerouslySetInnerHTML={{ __html: text }}
              />
            </div>

            <div className="about__location">
              <PinIcon
                mainClassName="about__location-icon"
                strokeClassName="about__location-icon-stroke"
              />
              <div className="about__location-text-block">
                <p className="about__location-title">{place}</p>
                <p className="about__location-subtitle">{city}</p>
              </div>
            </div>

            <Button
              text={btnText}
              link={EXHIBITION_LINK}
              className="about__btn"
              isSmallBtn
            />
          </div>

          <div className="about__img-block">
            <img className="about__scheme" src={scheme} alt="" />
            <svg
              className="about__scheme-bg"
              xmlns="http://www.w3.org/2000/svg"
              width="675"
              height="959"
              viewBox="0 0 675 959"
              fill="none"
            >
              <path
                className="about__scheme-bg-fill"
                opacity="0.6"
                d="M0.226318 0.12085L674.695 82.0339V958.957L0.226318 877.044L0.226318 0.12085Z"
                fill="#06002D"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
