import platinum from '../images/sponsors/icons/platinum.webp';
import gold from '../images/sponsors/icons/gold.webp';
import silver from '../images/sponsors/icons/silver.webp';
import conference from '../images/sponsors/icons/conference.webp';
import coffee from '../images/sponsors/icons/coffee.webp';
import registration from '../images/sponsors/icons/registration.webp';
import badge from '../images/sponsors/icons/badge.webp';
import souvenir from '../images/sponsors/icons/souvenir.webp';

import Siddharth_Gupta from '../images/conference/speakers/Siddharth_Gupta.webp'
import Ilya_Machavariani from '../images/conference/speakers/Ilya_Machavariani.webp'
import Norbert_Rubicsek from '../images/conference/speakers/Norbert_Rubicsek.webp'
import Mikheil_Demetrashvili from '../images/conference/speakers/Mikheil_Demetrashvili.webp'
import Davit_Kikvidze from '../images/conference/speakers/Davit_Kikvidze.webp'
import George_Mamulaishvili from '../images/conference/speakers/George_Mamulaishvili.webp'
import Ninia_Chkheidze from '../images/conference/speakers/Ninia_Chkheidze.webp'
import Makhanalin_Bolat from '../images/conference/speakers/Makhanalin_Bolat.webp'
import John_Bamidele from '../images/conference/speakers/John_Bamidele.webp'
import Yaroslav_Kozliuk from '../images/conference/speakers/Yaroslav_Kozliuk.webp'
import Ivan_Kurochkin from '../images/conference/speakers/Ivan_Kurochkin.webp'
import Lasha_Shikhashvili from '../images/conference/speakers/Lasha_Shikhashvili.webp'
import Giga_Vashakidze from '../images/conference/speakers/Giga_Vashakidze.webp'
import Nikita_Velichko from '../images/conference/speakers/Nikita_Velichko.webp'
import Egor_Rozinskii from '../images/conference/speakers/Egor_Rozinskii.webp'


import { PhoneIcon, MailIcon, WebIcon } from '../icons/icons';

export const RU_LANGUAGE = 'RU';
export const EN_LANGUAGE = 'EN';
export const VALIDE_LANG = [EN_LANGUAGE, RU_LANGUAGE];
export const LANGUAGES = {
  [EN_LANGUAGE]: {
    title: 'English',
    value: EN_LANGUAGE,
  },
  [RU_LANGUAGE]: {
    title: 'Русский',
    value: RU_LANGUAGE,
  },
};

export const MAIN_LINK = '/';
export const ABOUT_LINK = '/about';
export const AGENDA_LINK = '/agenda';
export const EXHIBITION_LINK = '/exhibition';
export const SPONSORS_LINK = '/sponsors';
export const PARTNERS_LINK = '/partners';
export const CONTACTS_LINK = '/contacts';
export const BUY_TICKET_LINK = '#';
export const RIXOS_LINK =
  'https://www.rixos.com/ru/hotel-resort/rixos-water-world-aktau';

export const NAV_LINKS = [
  {
    link: MAIN_LINK,
    title: {
      [RU_LANGUAGE]: 'Главная',
      [EN_LANGUAGE]: 'Home',
    },
  },
  {
    link: ABOUT_LINK,
    title: {
      [RU_LANGUAGE]: 'О конференции',
      [EN_LANGUAGE]: 'Conference',
    },
  },
  {
    link: AGENDA_LINK,
    title: {
      [RU_LANGUAGE]: 'Программа',
      [EN_LANGUAGE]: 'Agenda',
    },
  },
  {
    link: EXHIBITION_LINK,
    title: {
      [RU_LANGUAGE]: 'Выставка',
      [EN_LANGUAGE]: 'Exhibition',
    },
  },
  {
    link: SPONSORS_LINK,
    title: {
      [RU_LANGUAGE]: 'Спонсоры',
      [EN_LANGUAGE]: 'Sponsors',
    },
  },
  {
    link: PARTNERS_LINK,
    title: {
      [RU_LANGUAGE]: 'Партнеры',
      [EN_LANGUAGE]: 'Partners',
    },
  },
  {
    link: CONTACTS_LINK,
    title: {
      [RU_LANGUAGE]: 'Контакты',
      [EN_LANGUAGE]: 'Contacts',
    },
  },
];

export const PARTNER_TYPE = {
  value: 'partner',
  label: {
    [RU_LANGUAGE]: 'Стать партнером',
    [EN_LANGUAGE]: 'Become Partner',
  },
};
export const ATTENDER_TYPE = {
  value: 'attender',
  label: {
    [RU_LANGUAGE]: 'Стать участником',
    [EN_LANGUAGE]: 'Become Attender',
  },
};
export const SPONSOR_TYPE = {
  value: 'sponsor',
  label: {
    [RU_LANGUAGE]: 'Стать спонсором',
    [EN_LANGUAGE]: 'Become Sponsor',
  },
};
export const RENT_TYPE = {
  value: 'rent',
  label: {
    [RU_LANGUAGE]: 'Аренда площади',
    [EN_LANGUAGE]: 'Rent Area',
  },
};
export const GENERAL_TYPE = {
  value: 'general',
  label: {
    [RU_LANGUAGE]: 'Общие вопросы',
    [EN_LANGUAGE]: 'General Inquiries',
  },
};
export const SELECT_VALUES = [
  PARTNER_TYPE,
  ATTENDER_TYPE,
  SPONSOR_TYPE,
  RENT_TYPE,
  GENERAL_TYPE,
];

export const CONTACTS_PHONE_FIRST = {
  label: '+7 708 684-64-90',
  link: 'tel:+77086846490',
};
export const CONTACTS_PHONE_SECOND = {
  label: '+9 9559 100-80-11',
  link: 'tel:+995591008011',
};
export const CONTACTS_EMAIL = {
  label: 'info@gcauthority.com',
  link: 'mailto:info@gcauthority.com',
};
export const CONTACTS_WEBSITE = {
  label: 'www.gcauthority.com',
  href: 'https://www.gcauthority.com',
};
export const CONTACTS_INFO = [
  {
    icon: PhoneIcon,
    value: [CONTACTS_PHONE_FIRST, CONTACTS_PHONE_SECOND],
  },
  {
    icon: MailIcon,
    value: [CONTACTS_EMAIL],
  },
  {
    icon: WebIcon,
    value: [CONTACTS_WEBSITE],
  },
];

export const SPONSOR_TYPES_LIST = [
  {
    type: 'platinum',
    icon: platinum,
    guantity: 1,
  },
  {
    type: 'gold',
    icon: gold,
    guantity: 1,
  },
  {
    type: 'silver',
    icon: silver,
    guantity: 2,
  },
  {
    type: 'conference',
    icon: conference,
    guantity: 2,
  },
  {
    type: 'coffee',
    icon: coffee,
    guantity: 5,
  },
  {
    type: 'registration',
    icon: registration,
    guantity: 2,
  },
  {
    type: 'badge',
    icon: badge,
    guantity: 2,
  },
  {
    type: 'souvenir',
    icon: souvenir,
    guantity: 2,
  },
];

export const SPEAKERS_MAP = {
  'Siddharth Gupta': Siddharth_Gupta,
  'Ilya Machavariani': Ilya_Machavariani,
  'Norbert Rubicsek': Norbert_Rubicsek,
  'Mikheil Demetrashvili': Mikheil_Demetrashvili,
  'Davit Kikvidze': Davit_Kikvidze,
  'George Mamulaishvili': George_Mamulaishvili,
  'Ninia Chkheidze': Ninia_Chkheidze,
  'Makhanalin Bolat': Makhanalin_Bolat,
  'John Bamidele': John_Bamidele,
  'John (Adeolu) Bamidele': John_Bamidele,
  'Yaroslav Kozliuk': Yaroslav_Kozliuk,
  'Ivan Kurochkin': Ivan_Kurochkin,
  'Lasha Shikhashvili': Lasha_Shikhashvili,
  'Giga Vashakidze': Giga_Vashakidze,
  'Nikita Velichko': Nikita_Velichko,
  'Egor Rozinskii': Egor_Rozinskii,

  //RU
  'Сиддхарт Гупта': Siddharth_Gupta,
  'Илья Мачавариани': Ilya_Machavariani,
  'Норберт Рубичек': Norbert_Rubicsek,
  'Михаил Деметрашвили': Mikheil_Demetrashvili,
  'Давит Киквидзе': Davit_Kikvidze,
  'Георгий Мамулаишвили': George_Mamulaishvili,
  'Ниния Чхеидзе': Ninia_Chkheidze,
  'Маханалин Болат': Makhanalin_Bolat,
  'Джон Бамиделе': John_Bamidele,
  'Джон (Адеолу) Бамиделе': John_Bamidele,
  'Ярослав Козлюк': Yaroslav_Kozliuk,
  'Иван Курочкин': Ivan_Kurochkin,
  'Лаша Шихашвили': Lasha_Shikhashvili,
  'Гига Вашакидзе': Giga_Vashakidze,
  'Никита Величко': Nikita_Velichko,
  'Егор Розинский': Egor_Rozinskii,
  // ... Add mappings for all SPEAKERS
};