import { useContext, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { TranslationContext } from '../../assets/contexts/translationContext';
import PageHeading from '../PageHeading/PageHeading';
import './Sponsors.css';
import stars from '../../assets/images/sponsors/heading-img.webp';
import starsMobile from '../../assets/images/sponsors/heading-img-mobile.webp';
import { SPONSOR_TYPE, SPONSOR_TYPES_LIST } from '../../assets/utils/constants';
import NavBar from './NavBar/NavBar';
import InfoBlock from './InfoBlock/InfoBlock';
import { FormPopupContext } from '../../assets/contexts/formPopupContext';

function Sponsors() {
  const {
    sponsors: { title, btnText, types, benefits, obligations, quantity },
  } = useContext(TranslationContext);
  const { setIsFormPopupOpen } = useContext(FormPopupContext);
  const [, setSearchParams] = useSearchParams();

  const typesList = SPONSOR_TYPES_LIST.map((item, i) => ({
    ...item,
    ...types[i],
  }));
  const [selected, setSelected] = useState(typesList[0]);

  function handleTypeClick(type) {
    const item = typesList.find((item) => item.type === type);
    setSelected(item);
  }

  function openFormPopup() {
    setIsFormPopupOpen(true);
    setSearchParams({ type: SPONSOR_TYPE.value });
  }

  return (
    <section className="sponsors">
      <PageHeading title={title} btnText={btnText} onClick={openFormPopup}>
        <img src={stars} alt="" className="sponsors__img" />
        <img
          src={starsMobile}
          alt=""
          className="sponsors__img sponsors__img_type_mobile"
        />
        <div className="sponsors__circle" />
      </PageHeading>

      <div className="sponsors__content">
        <div className="sponsors__container">
          <NavBar
            list={typesList}
            selected={selected.type}
            onClick={handleTypeClick}
          />

          <div className="sponsors__title-block">
            <img
              src={selected.icon}
              alt={selected.title}
              className="sponsors__type-icon"
            />
            <div className="sponsors__title-box">
              <p className="sponsors__type-title">{selected.title}</p>
              <div className="sponsors__subtitle-box">
                <p className="sponsors__subtitle-text sponsors__subtitle-text_type_opacity">
                  {quantity}
                </p>
                <p className="sponsors__subtitle-text">{selected.guantity}</p>
              </div>
            </div>
          </div>

          <div className="sponsors__info">
            <InfoBlock title={benefits} content={selected.benefits} />
            <InfoBlock title={obligations} content={selected.obligations} />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Sponsors;
