import { useContext } from 'react';
import { TranslationContext } from '../../assets/contexts/translationContext';
import './Footer.css';

function Footer() {
  const { footer } = useContext(TranslationContext);

  return (
    <footer className="footer">
      <p className="footer__text">{footer.text}</p>
      <p className="footer__text">{footer.date}</p>
    </footer>
  );
}

export default Footer;
