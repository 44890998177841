import { createContext } from 'react';
import { RU_TRANSLATION } from '../translation/RU';
import { EN_TRANSLATION } from '../translation/EN';

export const TranslationContext = createContext();

export const translations = {
  RU: RU_TRANSLATION,
  EN: EN_TRANSLATION,
};
