import { useContext, useState } from 'react';
import validator from 'validator';
import { TranslationContext } from '../../assets/contexts/translationContext';
import PageHeading from '../PageHeading/PageHeading';
import './Contacts.css';
import banner from '../../assets/images/contacts/heading-img.webp';
import bannerMobile from '../../assets/images/contacts/heading-img-mobile.webp';
import logo from '../../assets/images/contacts/gca-logo.webp';
import { PinIcon } from '../../assets/icons/icons';
import { CONTACTS_INFO } from '../../assets/utils/constants';
import Checkbox from '../Checkbox/Checkbox';
import Input from '../Input/Input';

function Contacts() {
  const {
    contacts: { title, text, location },
    form: {
      labels: { agreement, submit, name, surname, email, message },
    },
  } = useContext(TranslationContext);
  const [values, setValues] = useState({
    name: '',
    surname: '',
    email: '',
    message: '',
    agreement: false,
  });
  const [valuesValidity, setValuesValidity] = useState({
    name: {},
    surname: {},
    email: {},
    message: {},
    agreement: {},
  });
  const isFormValid = Object.values(valuesValidity).every(
    (value) => value.validState
  );

  function handleChange(e) {
    const { name, value: targetValue, checked, type } = e.target;
    const value = type === 'checkbox' ? checked : targetValue;

    switch (name) {
      case 'email':
        if (!value) {
          setValuesValidity((prevValue) => ({
            ...prevValue,
            [name]: {
              errorMessage: '',
              validState: false,
            },
          }));
        }
        if (value.length >= 2) {
          if (validator.isEmail(value)) {
            setValuesValidity((prevValue) => ({
              ...prevValue,
              [name]: {
                errorMessage: '',
                validState: true,
              },
            }));
          } else {
            setValuesValidity((prevValue) => ({
              ...prevValue,
              [name]: {
                errorMessage: !e.target.validity.valid
                  ? e.target.validationMessage
                  : 'Invalid email',
                validState: false,
              },
            }));
          }
        }
        setValues((prevValue) => ({
          ...prevValue,
          [name]: value,
        }));
        break;

      default:
        setValues((prevValue) => ({
          ...prevValue,
          [name]: value,
        }));
        setValuesValidity((prevValue) => ({
          ...prevValue,
          [name]: {
            errorMessage: '',
            validState: Boolean(value),
          },
        }));
        break;
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
  }

  return (
    <section className="contacts">
      <PageHeading title={title}>
        <img src={banner} alt="" className="contacts__img" />
        <img
          src={bannerMobile}
          alt=""
          className="contacts__img contacts__img_type_mobile"
        />
        <div className="contacts__circle" />
      </PageHeading>

      <div className="contacts__content">
        <div className="contacts__container">
          <div className="contacts__form-container">
            <p
              className="contacts__form-title"
              dangerouslySetInnerHTML={{ __html: text }}
            />
            <form className="contacts__form" onSubmit={handleSubmit}>
              <div className="contacts__inputs">
                <Input
                  name="name"
                  label={name}
                  value={values.name}
                  onChange={handleChange}
                  error={valuesValidity.name.errorMessage}
                />
                <Input
                  name="surname"
                  label={surname}
                  value={values.surname}
                  onChange={handleChange}
                  error={valuesValidity.surname.errorMessage}
                />
                <Input
                  name="email"
                  label={email}
                  type="email"
                  value={values.email}
                  onChange={handleChange}
                  error={valuesValidity.email.errorMessage}
                />
                <div className="contacts__input contacts__input_type_textarea">
                  <Input
                    name="message"
                    label={message}
                    value={values.message}
                    onChange={handleChange}
                    error={valuesValidity.message.errorMessage}
                    isTextArea
                  />
                </div>
              </div>

              <div className="contacts__buttons">
                <Checkbox
                  name="agreement"
                  checked={values.agreement}
                  label={agreement}
                  error={valuesValidity.agreement.errorMessage}
                  onChange={handleChange}
                />
                <button
                  className={`contacts__submit-button ${
                    !isFormValid ? 'contacts__submit-button_disabled' : ''
                  }`}
                  type="submit"
                >
                  {submit}
                </button>
              </div>
            </form>
          </div>

          <ul className="contacts__info-container">
            <img className="contacts__logo" src={logo} alt="gca-logo" />
            <li className="contacts__info-item">
              <PinIcon
                mainClassName="contacts__info-icon"
                strokeClassName="contacts__info-icon-stroke"
              />
              <div className="contacts__info-text-block">
                <p className="contacts__info-text">{location.address}</p>
                <p className="contacts__info-text contacts__info-text_size_small">
                  {location.city}
                </p>
              </div>
            </li>
            {CONTACTS_INFO.map((item) => (
              <li className="contacts__info-item">
                <item.icon
                  mainClassName="contacts__info-icon"
                  strokeClassName="contacts__info-icon-stroke"
                />
                <div className="contacts__info-text-block">
                  {item.value.map((value) => (
                    <a
                      className="contacts__info-link"
                      href={value.link || value.href}
                      target={value.href ? '_blank' : '_self'}
                      rel={value.href ? 'noopener noreferrer' : ''}
                      key={value.label}
                    >
                      <p className="contacts__info-text">{value.label}</p>
                    </a>
                  ))}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
}

export default Contacts;
